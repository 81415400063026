<template>
  <div>
    <v-dialog
      v-model="dialogResults"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar
          flat
          dark
          color="primary"
          style="background-color: #536d7a !important"
        >
          <v-btn icon dark @click="onClickButton()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>User</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card pl-4 style="margin-top: 10px; padding: 20px">
            <v-text-field
              v-model="name"
              label="Name"
              :error-messages="nameError"
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="E-Mail"
              :error-messages="emailError"
            ></v-text-field>
            <v-text-field
              v-model="company"
              label="Company"
              :error-messages="companyError"
            ></v-text-field>

            <v-select
              v-model="contractors"
              item-text="name"
              item-value="uid"
              :items="contractor_list"
              :menu-props="{ maxHeight: '400' }"
              label="Contractors"
              multiple
              :error-messages="contractorsError"
              persistent-hint
            ></v-select>

            <v-select v-model="role" :items="roles" label="Role"></v-select>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="password"
              label="Password"
              :error-messages="passwordError"
            ></v-text-field>
            <v-text-field
              v-model="password_confirm"
              label="Confirm password"
              :error-messages="passwordError"
            ></v-text-field>

            <v-alert
              border="top"
              color="red lighten-2"
              v-if="showErrorAlert"
              dark
            >
              User could not be saved!<br />
              {{ messageErrorAlert }}
            </v-alert>

            <v-btn
              style="margin-top: 40px"
              @click="save()"
              block
              depressed
              color="primary"
            >
              <span v-if="!uid">Save</span>
              <span v-if="uid">Update</span>
            </v-btn>
            <div style="text-align: right" v-if="uid">
              <v-btn
                style="margin-top: 40px"
                @click="deleteUser()"
                depressed
                color="danger"
              >
                Delete
              </v-btn>
            </div>
          </v-card>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { saveUser, deleteUser } from "@/services/adminarea.service";

export default {
  props: ["selectedRow"],
  name: "HelloWorld",

  data: () => ({
    dialogResults: true,
    resultList: [],
    name: null,
    email: null,
    uid: null,
    company: null,
    role: null,
    roles: ["User", "Admin"],
    states: ["a", "b", "c"],
    contractors: null,
    password: null,
    password_confirm: null,
    companyError: [],
    emailError: [],
    nameError: [],
    contractorsError: [],
    passwordError: [],
    showErrorAlert: false,
    messageErrorAlert: "",
  }),
  computed: {
    contractor_list: function () {
      const list = this.$store.state.contractorList;
      return list;
    },
  },
  methods: {
    onClickButton(event) {
      this.dialogResults = false;
      this.$emit("closed");
    },
    deleteUser() {
      console.log("_deleteUser");
      if (confirm("Do you really want to delete this entry?") == true) {
        console.log("wweg damit");

        deleteUser({
          uid: this.uid,
        }).then((d) => {
         
          if (d.data.list) {
            this.$store.commit("set_userList", d.data.list);
          }

          this.onClickButton();
        });
      }
    },
    save() {
      let error = 0;
      this.companyError = [];
      this.emailError = [];
      this.nameError = [];
      this.contractorsError = [];
      this.passwordError = [];

      if (!this.name || this.name.length < 3) {
        error++;
        this.nameError.push("Required");
      }
      if (!this.email || this.email.length < 3) {
        error++;
        this.emailError.push("Required");
      }
      if (!this.company || this.company.length < 3) {
        error++;
        this.companyError.push("Required");
      }
      if (!this.contractors || this.contractors.length == 0) {
        error++;
        this.contractorsError.push("Required");
      }

      if (!this.uid && (!this.password || this.password.length < 8)) {
        error++;
        this.passwordError.push(
          "Password must consist of at least 8 characters"
        );
      }

      if (this.uid && this.password && this.password.length > 0) {
        if (
          this.password.length < 8 &&
          this.password != this.password_confirm
        ) {
          error++;
          this.passwordError.push(
            "Password must consist of at least 8 characters"
          );
        }
      }

      this.showErrorAlert = false;

      if (error == 0) {
        saveUser({
          name: this.name,
          email: this.email,
          uid: this.uid,
          password: this.password,
          company: this.company,
          contractors: this.contractors,
          role: this.role,
        }).then((d) => {
          if (d.data.error) {
            this.showErrorAlert = true;
            this.messageErrorAlert = d.data.error_message;
          }

          if (d.data.list) {
            this.$store.commit("set_userList", d.data.list);
            this.onClickButton();
          }
        });
      }
    },
  },
  mounted() {
    if (this.selectedRow) {
      this.name = this.selectedRow.name;
      this.email = this.selectedRow.email;
      this.company = this.selectedRow.company;
      this.role = this.selectedRow.role;
      this.contractors = this.selectedRow.contractors;
      this.uid = this.selectedRow.uid;
    }

    if (!this.role) {
      this.role = "User";
    }
  },
};
</script>

<style scoped  lang="scss">
.result-row {
  padding: 10px !important;
  vertical-align: top;
}
</style>
