<template>
 <v-container fluid fill-height style="background-color:#f4f6f9;">
    <v-layout>
        
      <v-flex md3 pa-3>
        <v-card style="background-color:#FAFBFD;"    >
            <v-toolbar style="background-color:#EF8733;" dark flat  >
                <v-toolbar-title class="title-sm">
                <span>Filter</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-row style="padding:12px;">
            <v-col cols="12"  >
                <v-combobox
                v-model="filterState"
                :items="state_list"
                label="State"
                ></v-combobox>
        
                <v-combobox
                v-model="filterMachine"
                :items="machine_list"
                label="Machine"
                ></v-combobox>

                <v-combobox
                v-model="filterProject"
                :items="project_list"
                label="Projects"
                ></v-combobox>

              

                <v-btn block @click="filterStart(true)">
                    Search
                </v-btn>
            </v-col>
            </v-row>

        </v-card> 
      </v-flex>
      <v-flex md9 pa-3>

        <v-card pl-4 class="mb-3"   >
            <div class="col col-12">
                <div class="row">
                    <div  class="py-0  col-sm-6 col-lg-3 col-12">
                        <v-card pl-4 flat >
                            <div class="v-card__text pa-5">
                            <div class="d-flex align-center">
                                <span class="lstick"></span>
                                <div class="ml-4 mr-1">
                                    <h5 class="subtitle-1 grey--text"> Total Results </h5>
                                    <h2 class="font-weight-regular"> {{totalResults}} </h2>
                                </div>
                            </div>
                        </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </v-card>

 <v-card v-if="result_list.length>0">
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Results
          </th>
          <th class="text-left">
            Count
          </th>
        </tr>
      </thead>
      <tbody>
        <tr

        style="cursor:pointer;"
          v-for="item in result_list"
          :key="item.key"
          @click="selectResultRow(item)"
        >
          <td>{{ item.key }}</td>
          <td>{{ item.doc_count }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 </v-card>
     
  <v-btn block  color="primary" v-if="result_list.length>0" @click="clearResuls()" style="margin:10px 0;">
    Back to components 
  </v-btn>
      <v-card v-if="component_list.length>0 && result_list.length==0">
          <v-data-table
            :headers="headers"
            :items="component_list"
            @click:row="handleClick"
          ></v-data-table>
        </v-card>
      </v-flex>
    </v-layout>


  <!--
    -->

        <v-dialog
          v-model="dialogResults"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
        >
          <v-card tile>
            <v-toolbar
              flat
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialogResults = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Results</v-toolbar-title>
              <v-spacer></v-spacer>
            
            
            </v-toolbar>
            <v-card-text>
              
              <v-list
                three-line
                subheader
              >
              
                <v-list-item  v-for="item in results_detail" style="border-bottom:1px solid #333333;padding-top:20px;"
          :key="item._id" >
                  <v-list-item-content>
                    <v-list-item-title>{{item._source.Anlage}} | {{item._source.Anlagentyp}} | {{item._source.Hersteller}}</v-list-item-title>
                    <v-list-item-subtitle>{{item._source.Standort}}<br /></v-list-item-subtitle>

                 
                    <h4>
                      {{item._source.Bauteil}}
                    </h4>
                      <div style="padding: 10px 0; ">
                       <h4>Projekt:</h4> {{item._source.Projekt}}
                      </div>
                         
                    <div style="padding: 10px 0; ">
                      Leistung: {{item._source.Leistung}}<br />Nabenhoehe: {{item._source.Nabenhoehe}}<br />Rotordurchmesser: {{item._source.Rotordurchmesser}}
                      </div>
                   <div style="padding: 10px 0; ">
                       <h4>Projektart:</h4> {{item._source.Projektart}}
                      </div>
                        <div style="padding: 10px 0; ">
                      Berichtsdatum: {{item._source.Berichtsdatum}}
                      </div>
                      
                                 <div style="padding: 10px 0; ">
                      Ergebnis:<br />{{item._source.Ergebnis}}
                      </div>
                                         <div style="padding: 10px 0; ">
                      Empfehlung:<br />{{item._source.Empfehlung}}
                      </div>
                                                     <div style="padding: 10px 0; ">
                      Verantwortlich:<br />{{item._source.Verantwortlich}}
                      </div>
                
                 
                  </v-list-item-content>
             
                </v-list-item>
                   
              </v-list>
            

   
            </v-card-text>
  
            <div style="flex: 1 1 auto;"></div>
          </v-card>
        </v-dialog>









  </v-container>
</template>


<script lang="ts">


import { aggreateTest, aggreateInspectors, filterData, aggreateGeneral } from '@/services/discover.service';  

export default  {

    data () {
      return {
        dialogResults: false, 
        totalResults: 0, 
        chartLoaded: false, 
        results_detail: null, 
        seriesState: [{
           name: 'State',
            data: []
          }], 

        chartOptions: {
          chart: {
            type: 'bar',
          
              animations: {
              enabled: true,
              dynamicAnimation: {
                  enabled: true,
                  speed: 350
              }
              },
          },
          legend: {
            show: false
          },
          title: {
              text: "States"
          },
          plotOptions: {
            bar: {
              borderRadius: 0,
              horizontal: true,
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [],
          }
        },

        buckets: [], 
        
        filterState: '',
        filterMachine: '',
        filterComponent: '',       
        filterProject: '',
        filterResult:'',
        inspector_list: [], 
        projectId: null,
        selInspector: null, 

        components: [], 
        db_results: [], 

        headers: [
          {
            text: 'Component', value: 'key',  align: 'start'
          },
          { text: 'Count', value: 'doc_count' }
        ],

        headersResults: [
          {
            text: 'Result', value: 'key',  align: 'start'
          },
          { text: 'Count', value: 'doc_count' }
        ],
      }
    },
  computed: {
    component_list: function() {
      return this.components; 
    },
    result_list: function() {
      return this.db_results; 
    },
    state_list: function() {
      let list = []
      if (this.$store.state.aggregationStates) {
        Object.entries(this.$store.state.aggregationStates).forEach(([key, entry]) => {
          if (entry["key"].length < 2) {
            list.push("Ohne Zuordnung")
          }
          else {
            list.push(entry["key"])
          }
        })
      }
      return list
    },
    machine_list: function() {
      let list = []
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list
    },
    project_list: function() {
      let list = []
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationProjects).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list
    },
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Analyze"

    let ref = this
    this.chartOptions.chart.events = {
      click: function(event, chartContext, config) {
        if (config.seriesIndex >= 0) {        
          ref.selectChartItem(config.config.series[config.seriesIndex].name, config.config.xaxis.categories[config.seriesIndex], config.config.series[config.seriesIndex].data[config.dataPointIndex])
        }
      }
    }

    aggreateGeneral({  }).then( x => {
      if (x.data && x.data.aggregations) {
        this.$store.commit('set_aggregationStates', x.data.aggregations['Status'].buckets )
        this.$store.commit('set_aggregationMachines', x.data.aggregations['Anlagentyp'].buckets )
        this.$store.commit('set_aggregationProjects', x.data.aggregations['Projektart'].buckets )
      }
    })

  },
  methods: {

      selectResultRow(item) {
       

        this.filterResult = item.key
        this.dialogResults = true

          filterData({  filterMachine: this.filterMachine, 
                        filterState: this.filterState, 
                        filterProject: this.filterProject,
                        filterResult: this.filterResult, 
                        filterComponent: this.filterComponent }).then( x => {

            if (x.data.hits && x.data.hits.hits) {
              
              
              this.results_detail = x.data.hits.hits
              console.log(this.results_detail)
            }
                          

          })


      },
      clearResuls() {
        this.db_results = []
        this.filterComponent = null
        this.filterResult = null
        this.filterStart(false)
      }, 
      handleClick(item) {
        console.log(item)
        this.filterComponent = item.key

        this.filterStart(false)

      },
      filterStart(btnClick) {
            if (btnClick) {
              this.clearResuls()
            }
           this.chartLoaded = false
           if ( this.filterState ) {
              filterData({  filterMachine: this.filterMachine, 
                            filterState: this.filterState, 
                            filterProject: this.filterProject,
                            filterComponent: this.filterComponent }).then( x => {

                              console.log(x)
                  if (x.data.aggregations) {
                    if (x.data.aggregations["Status"].buckets.length > 0) {
                      this.totalResults = x.data.aggregations["Status"].buckets[0].doc_count
                    }
                    else {
                      this.totalResults = 0
                    }

                    if (x.data.aggregations["Bauteil"] && x.data.aggregations["Bauteil"].buckets.length > 0) {
                      this.components = x.data.aggregations["Bauteil"].buckets
                    }
                    else {
                      this.components = []
                    }


                    if (x.data.aggregations["Ergebnis"] && x.data.aggregations["Ergebnis"].buckets.length > 0) { 
                      this.db_results = x.data.aggregations["Ergebnis"].buckets
                    }

                  }

              })
           }
           else {
             alert("Please select a state")
           }

      },


  }
}

</script>

<style scoped lang="scss">

    .title-sm {
        font-size: 1rem; 
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }

</style>