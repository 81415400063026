<template>
  <div>
    <v-card pl-4 style="margin-bottom: 20px">
      <div class="col col-12">
        <!--
        <button @click="saveState()">SAVE STATE</button>
        <button @click="restoreState()">RESTORE STATE</button>
        -->
        <div class="row">
          <div class="py-0 col-8">
            <v-container>
              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="machineKeyword"
                    v-show="switchMachine"
                    label="Machine (Wildcard)"
                  ></v-text-field>
                  <v-autocomplete
                    v-model="machineFilter"
                    :items="machine_list"
          
                    v-show="!switchMachine"
                    label="Machine"
                    multiple
                    small-chips
                    auto-select-first
                    chips
                    clearable
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    style="margin-top: 20px"
                    v-model="switchMachine"
                    :label="`Wildcard`"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row style="margin-top: -40px">
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="keyword"
                    label="Keyword (Results)"
                  ></v-text-field>
                  <v-btn small clearable text @click="tableSettings()">
                    Table Settings
                  </v-btn>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    v-if="!processing"
                    @click="search()"
                    clearable
                    color="primary"
                    style="margin-top: 20px"
                  >
                    Search
                  </v-btn>

                  <v-progress-circular
                    style="margin-top: 20px"
                    indeterminate
                    v-if="processing"
                    color="primary"
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div class="py-0 col-4">
            <v-card pl-4 flat>
              <div class="v-card__text pa-5">
                <div class="d-flex align-center">
                  <span class="lstick"></span>
                  <div class="ml-4 mr-1">
                    <h5 class="subtitle-1 grey--text">Results</h5>
                    <h2 class="font-weight-regular">{{ resultCount }}</h2>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </v-card>
    <ag-grid-vue
      style="width: 100%"
      v-bind:style="{ height: tableH + 'px' }"
      class="ag-theme-alpine"
      :enableCharts="true"
      :enableRangeSelection="true"
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      @cell-clicked="onCellClicked"
      :sideBar="sideBar"
      @grid-ready="onGridReady"
      @filter-changed="filterChanged"
      :rowData="rowData"
    >
    </ag-grid-vue>

    <ResultImage v-if="false" />

    <TableSettings
      @closed="tableSettingsClosed"
      v-if="showTableSettings"
      :tableState="tableState"
      @changeTblState="changeTblState"
      category="results"
    ></TableSettings>

    <LightBox
      ref="lightbox"
      :show-caption="false"
      :show-light-box="false"
      :media="media"
    ></LightBox>

    <v-overlay :opacity="0.3" v-if="showLoader">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";

import TableSettings from "../components/TableSettings";

import {
  searchFilter,
  searchResults,
  loadImgUrl,
} from "@/services/discover.service";

import axios from "axios";
import ResultImage from "../components/ResultImage.vue";
import { EventBus } from "@/event-bus";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack

export default {
  data() {
    return {
      loading: false,
      nextItem: 1,
      items: [],
      tableState: {},
      columnDefs: null,
      defaultColDef: null,
      //queryType: null,
      //query_list: ['match', 'match phrase', 'wildcard'],
      rowData: null,
      gridOptions: null,
      machineFilter: "",
      keyword: "",
      switchMachine: false,
      showLoader: false,
      machineKeyword: null,
      resultCount: 0,
      processing: false,
      tableH: 200,
      imgData: [],
      showTableSettings: false,
      media: [],
    };
  },
  components: {
    AgGridVue,
    ResultImage,
    LightBox,
    TableSettings,
  },
  computed: {
    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort();
    },
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Inspection results";

    EventBus.$on("img_selected", this.imgSelectedHandler);

    searchFilter()
      .then((x) => {
        this.$store.commit(
          "set_aggregationMachines",
          x.data.aggregations["Anlagentyp"].buckets
        );
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });

    this.resizeWindow();
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  destroyed() {
    EventBus.$off("img_selected", this.imgSelectedHandler);
    window.removeEventListener("resize", this.resizeWindow);
  },
  beforeMount() {
    this.gridOptions = {
      // EVENTS
      // Add event handlers
      onCellClicked: (event) =>
        function (x) {
          console.log("cellClicked");
        },
      //onColumnResized: event => console.log('A column was resized'),
      onGridReady: (event) => console.log("The grid is now ready"),
    };

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      autoHeight: true,
      editable: true,
      filter: "agTextColumnFilter",
      // floatingFilter: true,
      /*
                // set the default column width
                //width: 150,
                // make every column editable
                // make every column use 'text' filter by default
                filter: 'agTextColumnFilter',
                // enable floating filters by default
                floatingFilter: true,
                // make columns resizable
                resizable: true,
                sideBar: null,
                // autoHeight: true,
              */
    };
    this.columnDefs = [
      //{  headerName: "Manufacturer", field: "Hersteller", sortable: true, filter: true },
      //{  headerName: "Type", field: "Anlagentyp", sortable: true, filter: true },
      { headerName: "State", field: "Status", sortable: true, filter: true },
      {
        headerName: "Serialnumber",
        field: "Anlage",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Component",
        field: "Bauteil",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Project type",
        field: "Projektart",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Pic",
        field: "Bilder",
        sortable: true,
        editable: false,
        filter: false,
        cellRendererFramework: "ResultImage",
      },
      { headerName: "Result", field: "Ergebnis", sortable: true },
      { headerName: "Result EN", field: "ErgebnisEn", sortable: true },

      {
        headerName: "Order year",
        field: "Auftragsjahr",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Contractor",
        field: "Auftragnehmer",
        sortable: true,
        filter: true,
      },

      {
        headerName: "Machine",
        field: "Anlagentyp",
        sortable: true,
        filter: true,
      },
      { headerName: "Report", field: "Bericht", sortable: true, filter: true },
      {
        headerName: "Report date",
        field: "Berichtsdatum",
        sortable: true,
        filter: true,
      },

      {
        headerName: "Reporting year",
        field: "Berichtsjahr",
        sortable: true,
        filter: true,
      },
      { headerName: "Recommendation", field: "Empfehlung", sortable: true },
      {
        headerName: "Recommendation EN",
        field: "EmpfehlungEn",
        sortable: true,
      },
      { headerName: "Location", field: "Ort", sortable: true, filter: true },
      { headerName: "Customer", field: "Kunde", sortable: true, filter: true },
      {
        headerName: "Subcontractor",
        field: "Unterauftragnehmer",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Responsible",
        field: "Verantwortlich",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Commissioning date",
        field: "Inbetriebnahmedatum",
        sortable: true,
        filter: true,
      },
      { headerName: "Power", field: "Leistung", sortable: true, filter: true },
      {
        headerName: "Hub height",
        field: "Nabenhoehe",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Rotor diameter",
        field: "Rotordurchmesser",
        sortable: true,
        filter: true,
      },
      { headerName: "Source", field: "Quelle", sortable: true, filter: true },
    ];

    this.sideBar = "columns";
  },
  methods: {
    changeTblState(tblstate) {
      if (tblstate) {
        this.gridOptions.columnApi.setColumnState(tblstate.colState);
        this.gridOptions.columnApi.setColumnGroupState(tblstate.groupState);
        this.gridOptions.api.setSortModel(tblstate.sortState);
        this.gridOptions.api.setFilterModel(tblstate.filterState);
      }
    },
    tableSettings() {
      this.tableState = {
        colState: this.gridOptions.columnApi.getColumnState(),
        groupState: this.gridOptions.columnApi.getColumnGroupState(),
        sortState: this.gridOptions.api.getSortModel(),
        filterState: this.gridOptions.api.getFilterModel(),
      };
      this.showTableSettings = true;
    },
    tableSettingsClosed() {
      this.showTableSettings = false;
    },
    openGallery() {
      this.$refs.lightbox.showImage(0);
    },
    imgSelectedHandler(data) {
      this.showLoader = true;
      loadImgUrl(data).then((list) => {
        this.imgData = list.data;

        this.media = [];

        this.showLoader = false;
        if (this.imgData) {
          for (let i = 0; i < this.imgData.length; i++) {
            this.media.push({
              // For an image
              type: "image", // Can be omitted for image
              thumb: this.imgData[i],
              src: this.imgData[i],
            });
          }
        }

        if (this.media.length > 0) {
          this.openGallery(0);
        }
      });
    },
    saveState() {
      this.tableState = {
        colState: this.gridOptions.columnApi.getColumnState(),
        groupState: this.gridOptions.columnApi.getColumnGroupState(),
        sortState: this.gridOptions.api.getSortModel(),
        filterState: this.gridOptions.api.getFilterModel(),
      };

      console.log("column state saved", this.tableState);
    },
    restoreState() {
      if (this.tableState) {
        this.gridOptions.columnApi.setColumnState(this.tableState.colState);
        this.gridOptions.columnApi.setColumnGroupState(
          this.tableState.groupState
        );
        this.gridOptions.api.setSortModel(this.tableState.sortState);
        this.gridOptions.api.setFilterModel(this.tableState.filterState);
      }

      console.log("column state restored");
    },
    onCellClicked(e) {
      console.log("onCellClicked", e);
    },
    resizeWindow(e) {
      this.tableH = window.outerHeight - 340;
    },
    search() {
      if (
        (!this.switchMachine &&
          (!this.machineFilter || this.machineFilter.length == 0)) ||
        (this.switchMachine &&
          (!this.machineKeyword || this.machineKeyword.length < 2))
      ) {
        alert("No machine selected");
        return;
      }
      if (!this.keyword) {
        alert("No keyword");
        return;
      }
      if (this.keyword.length < 4) {
        alert("Keyword to short (min 4 characters)");
        return;
      }

      this.processing = true;
      searchResults({
        machineFilter: this.machineFilter,
        machineKeyword: this.machineKeyword,
        switchMachine: this.switchMachine,
        keyword: this.keyword,
      })
        .then((x) => {
          this.prepareResult(x.data.hits.hits);
          this.resultCount = x.data.hits.total.value;
          this.processing = false;
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    },
    onGridReady(params) {
      this.rowData = [];
      //console.log("getDisplayedRowCount",  this.gridOptions.api.getDisplayedRowCount())
    },
    filterChanged() {
      console.log("filterChanged", this.gridOptions.api.getDisplayedRowCount());
    },
    stateToText(field) {
      if (field == "ok") {
        return "V";
      }
      if (field == "info") {
        return "I";
      }
      if (field == "recommendation") {
        return "E";
      }
      if (field == "warning") {
        return "P";
      }
      if (field == "alarm") {
        return "PP";
      }
      if (field == "critical") {
        return "PPP";
      }
      return " ";
    },
    prepareResult(list) {
      this.rowData = [];

      for (let i = 0; i < list.length; i++) {
        this.rowData.push(list[i]._source);

        if (list[i]._source["Berichtsdatum"]) {
          list[i]._source["Berichtsdatum"] = moment(
            list[i]._source["Berichtsdatum"]
          ).format("DD.MM.YYYY");
        }

        if (list[i]._source["Inbetriebnahmedatum"]) {
          list[i]._source["Inbetriebnahmedatum"] = moment(
            list[i]._source["Inbetriebnahmedatum"]
          ).format("DD.MM.YYYY");
        }

        if (list[i]._source["Status"]) {
          list[i]._source["Status"] = this.stateToText(
            list[i]._source["Status"]
          );
        }
      }
    },
  },
};
</script>



<style  lang="scss">
$purple: #5c4084;

.heading {
  text-align: center;
  h1 {
    background: -webkit-linear-gradient(#fff, #999);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    text-align: center;
    margin: 0 0 5px 0;
    font-weight: 900;
    font-size: 4rem;
    color: #fff;
  }
  h4 {
    color: lighten(#5c3d86, 30%);
    text-align: center;
    margin: 0 0 35px 0;
    font-weight: 400;
    font-size: 24px;
  }
}

.list-group-wrapper {
  position: relative;
}
.list-group {
  overflow: auto;
  height: 90vh;
  border: 2px solid #dce4ec;
  border-radius: 5px;
}
.list-group-item {
  margin-top: 1px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #dce4ec;
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 9;
  background: $purple;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>