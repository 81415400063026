<template>
  <div>


    <v-dialog
      v-model="dialogResults"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar
          flat
          dark
          color="primary"
          style="background-color: #536d7a !important"
        >
          <v-btn icon dark @click="onClickButton()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >{{ selectedRow.component }} ({{
              reports
            }}
            reports)</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-subheader style="font-size: 18px; font-weight: bold">
            <span v-if="!selectedRow.switchMachine">{{
              selectedRow.machine.join(",")
            }}</span>
            <span v-if="selectedRow.switchMachine">{{
              selectedRow.machineKeyword
            }}</span>
            | {{ stateToText }}</v-subheader
          >

          <v-card pl-4 v-show="resultList.length > 0" style="margin-top: 10px">
            <div style="padding: 6px 20px">
              <v-text-field
                v-model="searchkey"
                label="Search"
                clearable
              ></v-text-field>
            </div>

            <v-simple-table fixed-header height="70vh">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left cursor-pointer"
                      @click="sortBy('result')"
                    >
                      Result
                    </th>
                    <th
                      class="text-left cursor-pointer"
                      @click="sortBy('recommendation')"
                    >
                      Recommendation
                    </th>
                    <th
                      class="text-center cursor-pointer"
                      @click="sortBy('images')"
                    >
                      Pic
                    </th>

                    <th
                      class="text-center cursor-pointer"
                      @click="sortBy('count')"
                    >
                      Count
                    </th>
                    <th class="text-center cursor-pointer">
                      Occurrence in report
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in total_results" :key="item.key">
                    <td class="result-row">
                      <span v-html="item.result"></span>
                      <span
                        v-if="item.resultEn && item.resultEn.length > 1"
                        v-html="item.result"
                      ></span>
                    </td>
                    <td class="result-row">
                      <span>{{ item.recommendation }}</span>
                      <span
                        v-if="
                          item.recommendationEn &&
                          item.recommendationEn.length > 1
                        "
                        >{{ item.recommendation }}</span
                      >
                    </td>
                    <td class="result-row text-center">
                      <v-icon
                        @click="showImages(item.id, item.images)"
                        v-if="item.images.length > 0"
                        class="imgimg"
                        >image</v-icon
                      >
                    </td>
                    <td class="result-row text-center">{{ item.count }}</td>
                    <td class="result-row text-center">
                      {{
                        ((item.count / reports_count) * 100).toFixed(2) > 100
                          ? 100
                          : ((item.count / reports_count) * 100).toFixed(2)
                      }}%
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>

      <v-overlay
      :opacity="0.3"
      v-if="showLoader"
    >
      <v-progress-circular indeterminate size="64">
        
      </v-progress-circular>
    </v-overlay>

    </v-dialog>


  </div>
</template>

<script>
import {
  getResultsForComponent,
  loadImgUrl,
} from "@/services/discover.service";

import { EventBus } from "@/event-bus";
export default {
  props: ["selectedRow", "reports"],
  name: "HelloWorld",

  data: () => ({
    dialogResults: true,
    resultList: [],
    searchkey: null,
    imgData: null, 
    media: [], 
    showLoader: false, 
    mapSort: {},
  }),
  computed: {
    stateToText() {
      if (this.selectedRow.state == "ok") {
        return "V (ok)";
      }
      if (this.selectedRow.state == "info") {
        return "I (info)";
      }
      if (this.selectedRow.state == "recommendation") {
        return "E (recomm.)";
      }
      if (this.selectedRow.state == "warning") {
        return "P (warning)";
      }
      if (this.selectedRow.state == "alarm") {
        return "PP (alarm)";
      }
      if (this.selectedRow.state == "critical") {
        return "PPP (critical)";
      }
      return " ";
    },

    reports_count() {
      return this.$store.state.machineReports;
    },
    total_results() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.resultList.filter((item) => {
          return (
            !item.result ||
            item.result.toLowerCase().indexOf(this.searchkey.toLowerCase()) !==
              -1
          );
        });
      }

      return this.resultList;
    },
  },
  methods: {
    openGallery() {
      this.$refs.lightbox.showImage(0);
    }, 
    showImages(id, images) {

      this.showLoader = true
      loadImgUrl({ id: id, list: images }).then((list) => {
        this.imgData = list.data;

        this.media = [];
        this.showLoader = false

        if (this.imgData) {
          for (let i = 0; i < this.imgData.length; i++) {
            this.media.push({
              // For an image
              type: "image", // Can be omitted for image
              thumb: this.imgData[i],
              src: this.imgData[i],
            });
          }
        }

        if (this.media.length > 0) {
         // this.openGallery(0);
         EventBus.$emit("img_selected", this.media);
        }
      });

      return;
    },

    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },
    sortBy(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.resultList = this.resultList.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    findInResults(str) {
      for (let k = 0; k < this.resultList.length; k++) {
        if (this.resultList[k].result == str) {
          return this.resultList[k];
        }
      }
      return null;
    },
    onClickButton(event) {
      this.dialogResults = false;
      this.$emit("closed");
    },
    groupResults(list) {
      console.log("list", list);

      this.resultList = [];

      for (let m = 0; m < list.length; m++) {
        let entry = this.findInResults(list[m]["_source"]["Ergebnis"]);

        if (entry) {
          entry.count = entry.count + 1;
        } else {
          entry = {
            count: 1,
            id: list[m]["_source"]["ResultId"],
            result: list[m]["_source"]["Ergebnis"],
            recommendation: list[m]["_source"]["Empfehlung"],
            resultEn: list[m]["_source"]["ErgebnisEn"],
            recommendationEn: list[m]["_source"]["EmpfehlungEn"],
            images: list[m]["_source"]["Bilder"]
              ? list[m]["_source"]["Bilder"].split(";")
              : [],
          };
          this.resultList.push(entry);
        }
      }

      for (let m = 0; m < this.resultList.length; m++) {
        this.resultList[m].result = this.resultList[m].result.replace(
          /\\n/g,
          "<br />"
        );
      }

      this.sortBy("count");
    },
  },
  mounted() {
  
    getResultsForComponent({
      stateFilter: this.selectedRow.state,
      machineFilter: this.selectedRow.machine,
      machineKeyword: this.selectedRow.machineKeyword,
      switchMachine: this.selectedRow.switchMachine,
      componentFilter: this.selectedRow.component,
      limit: 1000,
      offset: 0,
    }).then((x) => {
      this.groupResults(x.data.hits.hits);
    });
  },

};
</script>

<style scoped  lang="scss">
.result-row {
  padding: 10px !important;
  vertical-align: top;
}
</style>
