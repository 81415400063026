<template>
  <v-container fluid fill-height>
       <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="blue-grey ">
                <v-toolbar-title>Login</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-alert
                border="left"
                color="red"
                dense
                icon="error"
                type="error"
                v-if="error"
              >
                {{errorMessage}}
              </v-alert>
              <v-card-text>
                <v-form >
                  <v-text-field @keyup="keyUp($event)" v-model="formEmail"  name="login" label="E-Mail" type="text"></v-text-field>
                  <v-text-field @keyup="keyUp($event)" v-model="formPwd" id="password"  name="password" label="Password" type="password"></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn   @click="login()">Sign In</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
  </v-container>
</template>


<script lang="ts">

import { doLogin } from '@/services/login.service';  
import axios from 'axios';

export default  {

  data () {
    return {

      formEmail: "",
      formPwd: "",
      error: false,
      errorMessage: "",
     
    }
  },
  methods: {

    login: function () {
      this.error = false

      this.$store.commit('auth_request')

      doLogin({ email: this.formEmail, password:  this.formPwd }).then( x => {
        console.log(x)
        if (x.status == 'error') {
          this.error = true
          this.errorMessage = x.message
          this.$store.commit('auth_error')
          localStorage.removeItem('token')
        } else {
          const token = x.data.token
          const user = x.data.user
          const role = x.data.role


          this.$store.state.user_role = role
          localStorage.setItem('user_role', role)
       
          localStorage.setItem('token', token)
          axios.defaults.headers.common['x-access-token'] = token
          this.$store.commit('auth_success', {token, user})
          this.$router.push({ name: 'overview' })
        }
      })
    },
    keyUp: function (ev) {
      if (ev.keyCode == 13) {
        this.login()
      }
    }
    
  },
  mounted() {

     
  }
}

</script>

<style lang="scss">

  
</style>
