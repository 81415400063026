import * as axios from 'axios';

function aggreateTest() {
    const data = {}

    const url = `/agg-data`;
    return axios.get(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function aggreateGeneral(data) {

    const url = `/analyze/aggreate-general`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });

}


function aggreateStats(data) {

    const url = `/analyze/aggreate-stats`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });

}


function aggreateMachines(data) {

    const url = `/analyze/aggreate-machines`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });

}


function resetProject(data) {
    const url = `/analyze/reset-project`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function filterMachineService(data) {

    const url = `/analyze/filter-machines`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });

}

function filterData(data) {

    const url = `/analyze/filter`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });

}

function aggregateExperts() {
    const data = {}

    const url = `/analyze/agg-experts`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function aggMainComponents(data) {
    const url = `/analyze/agg-maincomponents`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function aggResultsMachines(data) {
    const url = `/analyze/agg-resultsmachines`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function filterMainComponents(data) {
    const url = `/analyze/filter-maincomponents`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function aggProjectTypes(data) {
    const url = `/analyze/agg-project-types`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function aggProjectTypesYears(data) {
    const url = `/analyze/agg-project-types-years`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function filterExperts(data) {
    const url = `/analyze/filter-experts`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function filterComponents(data) {
    const url = `/analyze/filter-components`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function getResultsForComponent(data) {
    const url = `/analyze/get-results`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function searchResults(data) {
    const url = `/analyze/search-results`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function searchFilter(data) {
    const url = `/analyze/search-filter`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function userInfo(data) {
    const url = `/analyze/user-info`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function getMyProjects(data) {
    const url = `/analyze/my-projects`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function loadUser(data) {
    const url = `/analyze/load-user`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function importProjects(data) {
    const url = `/analyze/import-projects`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function removeProjects(data) {
    const url = `/analyze/remove-projects`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function loadImgUrl(data) {
    const url = `/signed-urls`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function addTblSettings(data) {
    const url = `/analyze/add-tblstate`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function loadTblSettings(data) {
    const url = `/analyze/load-tblstate`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function removeTblSettings(data) {
    const url = `/analyze/remove-tblstate`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function compareMachinesStep1(data) {
    const url = `/analyze/compare-machines-step1`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function compareMachinesStep2(data) {
    const url = `/analyze/compare-machines-step2`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

export {
    aggreateTest,
    filterData,
    aggreateMachines,
    filterMachineService,
    aggProjectTypes,
    aggregateExperts,
    filterExperts,
    filterMainComponents,
    filterComponents,
    getResultsForComponent,
    aggMainComponents,
    aggResultsMachines,
    loadImgUrl,
    searchResults,
    aggProjectTypesYears,
    searchFilter,
    resetProject,
    aggreateGeneral,
    userInfo,
    importProjects,
    removeProjects,
    getMyProjects,
    aggreateStats,
    loadUser,
    addTblSettings,
    loadTblSettings,
    removeTblSettings, 
    compareMachinesStep1,
    compareMachinesStep2,
}