<template>
  <div>
    <v-dialog
      v-model="dialogResults"
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar
          flat
          dark
          color="primary"
          style="background-color: #536d7a !important"
        >
          <v-btn icon dark @click="onClickButton()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Table Settings</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card pl-4 style="margin-top: 10px">
            <v-row>
              <v-col cols="7"> </v-col>
              <v-col cols="3">
                <v-text-field
                  label="Current state name"
                  v-model="tbl_state_name"
                  filled
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-btn
                  style="margin-top: 5px"
                  @click="addTblState()"
                  tile
                  depressed
                  color="success"
                >
                  <v-icon left> mdi-plus </v-icon>
                  Add state
                </v-btn>
              </v-col>
            </v-row>
            <div style="padding: 20px" v-if="!list || list.length == 0">
              No entries available.<br />Add your first state.
            </div>
            <v-simple-table v-if="list && list.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list" :key="item.uid">
                    <td>{{ item.createdAt | moment("MM.DD.YYYY - HH:mm") }}</td>
                    <td>{{ item.name }}</td>
                    <th>
                      <v-btn
                        color="primary"
                        dark
                        depressed
                        @click="chooseTblState(item)"
                      >
                        Select
                      </v-btn>
                      <v-btn
                        style="margin-left: 30px"
                        small
                        text
                        @click="removeTblState(item.uid)"
                      >
                        Delete
                      </v-btn>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

import {
  addTblSettings,
  loadTblSettings,
  removeTblSettings,
} from "@/services/discover.service";

export default {
  props: ["category", "tableState"],
  components: {},
  data() {
    return {
      dialogResults: true,
      displayValue: "",
      tbl_state_name: "",
      list: [],
    };
  },
  computed: {},
  mounted() {
    loadTblSettings({
        category: this.category,
    }).then((obj) => {
      this.list = obj.data;
    });
  },
  methods: {
    chooseTblState(item) {
      this.dialogResults = false;
      this.$emit("changeTblState", item.tblstate);
      this.$emit("closed");
    },
    removeTblState(uid) {
      removeTblSettings({
        uid: uid,
        category: this.category,
      }).then((obj) => {
        this.list = obj.data;
      });
    },
    addTblState() {
      if (this.tbl_state_name && this.tbl_state_name.length > 2) {
        addTblSettings({
          name: this.tbl_state_name,
          category: this.category,
          tblstate: this.tableState,
        }).then((obj) => {
          this.tbl_state_name = "";
          this.list = obj.data;
        });
      }
    },
    onClickButton(event) {
      this.dialogResults = false;
      this.$emit("closed");
    },
  },
};
</script>

<style>
</style>