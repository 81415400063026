import Vue from 'vue'
import Router from 'vue-router'
import store from './store.ts'

import Login from './views/Login.vue'
import Overview from './views/Overview.vue'
import Analyze from './views/Analyze.vue'
import Aggregation from './views/Aggregation.vue'
import Machines from './views/Machines.vue'
import CompareMachines from './views/CompareMachines.vue'



import ProjectTypes  from './views/ProjectTypes.vue'
import HotTable  from './views/HotTable.vue'
import Experts  from './views/Experts.vue'
import MainComponents  from './views/MainComponents.vue'
import Components   from './views/Components.vue'
import UserAccounts from './views/UserAccounts.vue'
import Projects from './views/Projects.vue'
import AccountStats from './views/AccountStats.vue'
import HelpCenter from './views/HelpCenter.vue'


//import NoAccess from './views/NoAccess.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  //scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'overview',
      component: Overview,
      meta: { 
        //requiresAuth: true
      },
      /*
      beforeEnter (to, from, next) {
        //auth.logout()
        next('/login')
      }
      */
  
    },

    {
      path: '/analyze',
      name: 'analyze',
      component: Analyze
    },
    {
      path: '/aggregation',
      name: 'aggregation',
      component: Aggregation
    },
    {
      path: '/machines',
      name: 'machines',
      component: Machines
    },
    {
      path: '/compare-machines',
      name: 'comparemachines',
      component: CompareMachines
    },
    {
      path: '/project-types',
      name: 'projecttypes',
      component: ProjectTypes
    },
    {
      path: '/experts',
      name: 'experts',
      component: Experts
    },
    {
      path: '/components',
      name: 'components',
      component: Components
    },
       
    {
      path: '/maincomponents',
      name: 'maincomponents',
      component: MainComponents
    },
    {
      path: '/hottable',
      name: 'hottable',
      component: HotTable
    },    
    {
      path: '/accounts',
      name: 'accounts',
      component: UserAccounts
    },
    {
      path: '/accountstats',
      name: 'accountstats',
      component: AccountStats
    },
    {
      path: '/projects',
      name: 'projects',
      component: Projects
    },   
    {
      path: '/helpcenter',
      name: 'helpcenter',
      component: HelpCenter
    },   
    {
      path: '/login',
      name: 'login',
      //component: NoAccess
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter (to, from, next) {
        //auth.logout()
        store.commit('logout')
        next('/login')
      }
    },

  ]
})


router.beforeEach((to, from, next) => {
  //next() 
  //return
  if(to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("store.getters.authStatus",  store.getters.authStatus)
    //console.log(store.state.token)
    if (store.getters.authStatus) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})


export default router