<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <div
                style="
                  background-color: rgb(205, 124, 58);
                  padding: 5px 10px;
                  color: #ffffff;
                "
              >
                Filter
              </div>
              <v-row style="padding: 12px">
                <v-col cols="6" style="padding-right: 40px">
                  <v-text-field
                    v-model="machineKeyword"
                    v-show="switchMachine"
                    label="Machine (Wildcard)"
                  ></v-text-field>
                  <v-combobox
                    v-model="machineFilter"
                    :items="machine_list"
                    v-show="!switchMachine"
                    multiple
                    small-chips
                    auto-select-first
                    chips
                    clearable
                    deletable-chips
                    label="Machine"
                  ></v-combobox>
                  <v-switch
                    style="margin-top: 0px"
                    v-model="switchMachine"
                    :label="`Wildcard`"
                  ></v-switch>
                  <v-btn block @click="filterStart(true)"> Search </v-btn>
                </v-col>
                <v-col cols="6">
                  <div style="width:100%;text-align: right;">
                    <v-btn small text clearable @click="tableSettings()">
                      Table Settings
                    </v-btn>
                  </div>

                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Main components</h5>
                        <h2 class="font-weight-regular">
                          {{ total_results.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card pl-4 v-show="db_result.length > 0" style="margin-top: 10px">
              <div>
                <!-- style="padding:6px 20px" -->
                <v-text-field
                  v-model="searchkey"
                  v-if="showCustomTable"
                  label="Search"
                  clearable
                ></v-text-field>
              </div>

              <ag-grid-vue
                style="width: 100%"
                v-bind:style="{ height: tableH + 'px' }"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :gridOptions="gridOptions"
                :enableCharts="true"
                :defaultColDef="defaultColDef"
                :sideBar="sideBar"
                :enableRangeSelection="true"
                :rowData="rowData"
              >
              </ag-grid-vue>

              <v-simple-table v-if="showCustomTable" fixed-header height="70vh">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        @click="sortBy('siteName')"
                        style="cursor: pointer"
                      >
                        Site
                      </th>
                      <th
                        class="text-left"
                        @click="sortBy('machineSerial')"
                        style="cursor: pointer"
                      >
                        Machine
                      </th>
                      <th
                        class="text-left"
                        @click="sortBy('component')"
                        style="cursor: pointer"
                      >
                        Component
                      </th>
                      <th
                        class="text-left"
                        @click="sortBy('companyName')"
                        style="cursor: pointer"
                      >
                        Manufacturer
                      </th>
                      <th
                        class="text-left"
                        @click="sortBy('mainComponentType')"
                        style="cursor: pointer"
                      >
                        Name
                      </th>
                      <th
                        class="text-left"
                        @click="sortBy('mainComponentName')"
                        style="cursor: pointer"
                      >
                        Serialnumber
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in components_result" :key="item.key">
                      <td>{{ item.siteName }}</td>
                      <td>{{ item.machineSerial }}</td>
                      <td>{{ item.component }}</td>
                      <td>{{ item.companyName }}</td>
                      <td>{{ item.mainComponentType }}</td>
                      <td>{{ item.mainComponentName }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>

    <TableSettings
      @closed="tableSettingsClosed"
      v-if="showTableSettings"
      :tableState="tableState"
      @changeTblState="changeTblState"
      category="maincomponents"
    ></TableSettings>
  </v-container>
</template>


<script lang="ts">
import {
  filterMainComponents,
  aggMainComponents,
} from "@/services/discover.service";
import moment from "moment";
import TableSettings from "../components/TableSettings";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  data() {
    return {
      dialogResults: false,
      chartLoaded: false,
      machineFilter: null,
      tableState: {},
      showTableSettings: false,
      searchkey: null,
      mapSort: {},
      total_results: 0,
      switchMachine: false,
      db_result: [],
      offset: 0,
      limit: 500,
      machineKeyword: null,
      loading: false,
      has_more: false,

      columnDefs: null,
      defaultColDef: null,
      rowData: null,
      gridOptions: null,
      tableH: 300,
      showCustomTable: false,
      sideBar: "columns",
      rows: [],
    };
  },
  components: {
    AgGridVue,
    TableSettings,
  },
  computed: {
    components_result() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.db_result.filter((item) => {
          return (
            !item.component ||
            item.component
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1 ||
            !item.siteName ||
            item.siteName
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1 ||
            !item.machineSerial ||
            item.machineSerial
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1 ||
            (!item.mainComponentType ||
              item.mainComponentType
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase())) !== -1
          );
        });
      }

      return this.db_result;
    },

    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort();
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  beforeMount() {
    this.gridOptions = {
      // EVENTS
      // Add event handlers
      onCellClicked: (event) =>
        function (x) {
          console.log(x);
        },
      //onColumnResized: event => console.log('A column was resized'),
      onGridReady: (event) => console.log("The grid is now ready"),
    };

    this.resizeWindow();

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      autoHeight: true,
      editable: true,
      filter: "agTextColumnFilter",
    };
    this.columnDefs = [
      { headerName: "Site", field: "siteName", sortable: true, filter: true },
      {
        headerName: "Machine Serial",
        field: "machineSerial",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Machine",
        field: "machinetypeNameCat",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Component",
        field: "component",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Manufacturer",
        field: "companyName",
        sortable: true,
        filter: true,
      },
      { headerName: "Name", field: "mainComponentType", sortable: true },
      {
        headerName: "Serialnumber",
        field: "mainComponentName",
        sortable: true,
      },
    ];
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 Data - Main components";

    // Detect when scrolled to bottom.
    const listElm = document.querySelector(".v-data-table__wrapper");
    if (listElm) {
      listElm.addEventListener("scroll", (e) => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.loadMore();
        }
      });
    }

    aggMainComponents({})
      .then((x) => {
        if (x.data && x.data.aggregations) {
          this.$store.commit(
            "set_aggregationMachines",
            x.data.aggregations["Anlagentyp"].buckets
          );
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  methods: {
    changeTblState(tblstate) {
      if (tblstate) {
        this.gridOptions.columnApi.setColumnState(tblstate.colState);
        this.gridOptions.columnApi.setColumnGroupState(tblstate.groupState);
        this.gridOptions.api.setSortModel(tblstate.sortState);
        this.gridOptions.api.setFilterModel(tblstate.filterState);
      }
    },
    tableSettings() {
      this.tableState = {
        colState: this.gridOptions.columnApi.getColumnState(),
        groupState: this.gridOptions.columnApi.getColumnGroupState(),
        sortState: this.gridOptions.api.getSortModel(),
        filterState: this.gridOptions.api.getFilterModel(),
      };
      this.showTableSettings = true;
    },
    tableSettingsClosed() {
      this.showTableSettings = false;
    },
    resizeWindow(e) {
      this.tableH = window.outerHeight - 430;
    },
    loadMore() {
      if (this.has_more) {
        this.offset++;
        this.filterStart(null);
      }
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },
    sortBy(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.db_result = this.db_result.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    filterStart(btnClick) {
      this.chartLoaded = false;
      if (
        (!this.switchMachine &&
          this.machineFilter &&
          this.machineFilter.length > 0) ||
        (this.switchMachine &&
          this.machineKeyword &&
          this.machineKeyword.length > 1)
      ) {
        if (btnClick) {
          this.db_result = [];
          this.offset = 0;
          this.has_more = true;
        }

        filterMainComponents({
          machineFilter: this.machineFilter,
          machineKeyword: this.machineKeyword,
          switchMachine: this.switchMachine,
          offset: this.offset,
          limit: this.limit,
        }).then((x) => {
          this.total_results = x.data.hits.total.value;

          for (let k = 0; k < x.data.hits.hits.length; k++) {
            this.db_result.push(x.data.hits.hits[k]._source);
          }

          this.rowData = this.db_result;

          this.has_more = x.data.hits.hits.length > 0;
        });
      } else {
        alert("Please select a machine");
      }
    },
  },
};
</script>

<style  lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.list .row {
  display: flex;
}

.list .row .data-cell,
.data-header-cell {
  position: relative !important;
  width: auto !important;
}

revogr-focus,
.selection-border-range,
.edit-input-wrapper {
  width: 100% !important;
}
</style>