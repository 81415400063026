import * as axios from 'axios';
import qs from 'qs';

function doLogin(formData) {
   
    const data = qs.stringify({
        email: formData.email,
        password: formData.password
    });

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };
  
    const url = `/users/authenticate`;
    return axios.post(url, data, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}

function accountSetup() {
    const data = {  }

    const url = `/checklist/account-setup`;
    return axios.get(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });   
}



export { doLogin, accountSetup }