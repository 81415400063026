import Vue from 'vue'
import Vuex from 'vuex'
import router from './router.ts'

Vue.use(Vuex)

const token = localStorage.getItem('token');
const initialState = token
    ?  'success'
    :  '' ;
 
export default new Vuex.Store({
  state: {
    status: initialState,
    user_role: null, 
    token: localStorage.getItem('token') || '',
    headerTitle: "8.2 IDAT", 
    user : {},
    userId: null,
    aggregationStates: [], 
    aggregationMachines: [], 
    aggregationComponents: [], 
    aggregationMachinesReports: [], 
    aggregationProjects: [], 
    aggregationLocations: [],
    aggregationReporting: [],
    aggregationExperts: [], 
    machineReports: 0, 
    contractorList: [],
    userList: [],
    projectList: []
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userRole: state => {
      if (state.user_role) {
        return state.user_role
      }

      if (localStorage.getItem('user_role')) {
        let role = localStorage.getItem('user_role')
        if (role) {
          //console.log(role)
          return JSON.parse(role)
        } 
      }
    },

  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      localStorage.removeItem('token')
      state.status = ''
      state.token = ''
    },
    set_aggregationStates(state, payload){
      state.aggregationStates = payload
    },
    set_aggregationMachines(state, payload){
      state.aggregationMachines = payload
    },
    set_aggregationMachinesReports(state, payload){
      state.aggregationMachinesReports = payload
    },
    set_aggregationProjects(state, payload){
      state.aggregationProjects = payload
    },
    set_aggregationLocations(state, payload){
      state.aggregationLocations = payload 
    },
    set_aggregationReporting(state, payload){
      state.aggregationReporting = payload 
    },
    set_aggregationExperts(state, payload){
      state.aggregationExperts = payload 
    },
    set_aggregationComponents(state, payload){
      state.aggregationComponents = payload 
    },
    set_machineReports(state, payload) {
      state.machineReports = payload
    },
    set_contractorList(state, payload){
      state.contractorList = payload
    },
    set_userList(state, payload){
      state.userList = payload
    },
    set_projectList(state, payload){
      state.projectList = payload
    },
  },
  actions: {
    api_error ({ commit, state }, error) {
      console.log("api_error", error)
      commit('logout')
      router.push({ name: 'logout' })
    },
  }
})
