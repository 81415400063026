<template>
  <v-app>
    <v-app-bar app color="blue-grey darken-1" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ headerTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu bottom left v-if="loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-title @click="appBarMenu(item)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item @click="sideBarMenu('overview')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('accounts')" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon>supervisor_account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Accounts</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('accountstats')" v-if="isAdmin">
            <v-list-item-icon>
              <v-icon>equalizer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('projects')" >
            <v-list-item-icon>
              <v-icon>supervisor_account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('aggregation')">
            <v-list-item-icon>
              <v-icon>equalizer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Data Overview</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('machines')">
            <v-list-item-icon>
              <v-icon>list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Machines</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('comparemachines')">
            <v-list-item-icon>
              <v-icon>list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Compare machines</v-list-item-title>
          </v-list-item>
          


          <v-list-item @click="sideBarMenu('projecttypes')">
            <v-list-item-icon>
              <v-icon>donut_large</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Project types</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('experts')">
            <v-list-item-icon>
              <v-icon>person</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inspectors</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('maincomponents')">
            <v-list-item-icon>
              <v-icon>list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Main components</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('components')">
            <v-list-item-icon>
              <v-icon>list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Component list</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('hottable')">
            <v-list-item-icon>
              <v-icon>list_alt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inspection results</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sideBarMenu('helpcenter')">
            <v-list-item-icon>
              <v-icon>help</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Help center</v-list-item-title>
          </v-list-item>
          <!--
          <v-list-item @click="sideBarMenu('analyze')">
            <v-list-item-icon>
              <v-icon>insights</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Analyze</v-list-item-title>
          </v-list-item>
          -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

import { userInfo } from '@/services/discover.service';  

export default {
  name: "App",

  components: {},
  computed: {
    headerTitle: function () {
      return this.$store.state.headerTitle;
    },
    isAdmin: function () {
      return this.$store.state.user_role == 'Admin'
    },
    loggedIn() {
      return this.$store.getters.authStatus;
    },
  },

  data: () => ({
    drawer: false,
    group: null,
    items: [{ title: "Logout", value: "logout" }],
  }),
  mounted() {
    this.$store.state.user_role = localStorage.getItem("user_role");

/*
    userInfo().then( x => {
      if (x.data) {
        
        this.$store.commit("set_projectList", x.data.projects);
      }
    })
*/
  },
  methods: {
    appBarMenu: function (item) {
      if (item.value == "logout") {
        this.$store.commit("logout");
        this.$router.push({ name: "login" });
      }
    },
    sideBarMenu: function (name) {
      this.$router.push({ name: name });
    },
  },
};
</script>


<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.lstick {
  width: 2px;
  background: #398bf7;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  position: absolute;
}

/*
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&amp;display=swap%7CRoboto:wght@400;700&amp;display=swap");

$font-family:'Quicksand';

.v-application {
  [class*='text-'] {
    color: #36405a;
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
*/
</style>