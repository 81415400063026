import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router.ts'
import store from './store.ts'
import  axios  from  'axios'
import moment from 'moment'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueApexCharts from 'vue-apexcharts'
  
Vue.config.productionTip = true        

Vue.prototype.$http = axios;
Vue.use(require('vue-moment'));

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YY HH:mm')  
  }
}); 

// __ User Login
const  accessToken = localStorage.getItem('token') 

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common['x-access-token'] = accessToken
}


const urlParams = new URLSearchParams(window.location.search);
const page_uid = urlParams.get('uid')

if (page_uid) {
  console.log("page_uid", page_uid)
  Vue.prototype.$http.defaults.headers.common['x-access-token'] = page_uid
}


console.log("page_uid", page_uid)

Vue.prototype.$apiBase = (Vue.config.productionTip) ? /*"https://8p2-analytics.gloo.io/api"*/ "/api"  : /*"https://8p2-analytics.gloo.io/api/"*/ "http://localhost:3050"
Vue.prototype.$http.defaults.baseURL = Vue.prototype.$apiBase;
  

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
