<template>
 <v-container fluid fill-height style="background-color:#f4f6f9;">
    <v-layout>


      <v-container fluid>
        <v-row >
          <v-col>
            <v-card style="background-color:#FAFBFD;"    >
              <div style="background-color:rgb(205, 124, 58);padding: 8px 10px;color:#ffffff;">
                Filter
              </div>
                <v-row style="padding:12px;">
                <v-col cols="12"  >
                    <v-combobox
                    v-model="reportingFilter"
                    multiple
                    :items="reporting_list"
                    label="Reporting year"
                    ></v-combobox>
                    <v-btn block @click="filterStart(true)">
                        Search
                    </v-btn>

                      <v-progress-circular
                          style="margin-top:20px;"
                          indeterminate
                          v-if="processing"
                          color="primary"
                        ></v-progress-circular>
                </v-col>
                </v-row>
            </v-card> 
          </v-col>
        </v-row>

        <v-row >
          <v-col> 
            <v-card class="pa-6" style="margin-top:10px;" v-if="chartLoaded" >
          <!-- <apexchart  v-if="chartLoaded" type="donut" height="650" :options="chartOptions" :series="seriesState"></apexchart> -->
           <apexchart   type="bar" :options="chartOptions2" :series="seriesState2"></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row >
          <v-col> 
            <v-card class="pa-6" style="margin-top:10px;" v-if="chartLoaded" >
           <apexchart   type="bar"  :options="chartOptionsProjects" :series="seriesReports"></apexchart>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
   

    </v-layout>
  </v-container>
</template>


<script lang="ts">


import { aggProjectTypes, aggProjectTypesYears } from '@/services/discover.service';  
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts';

export default  {
    components: {
      apexchart: VueApexCharts,
    },
    data () {
      return {
        dialogResults: false, 
        totalResults: 0, 
        chartLoaded: false, 
        reportingFilter: null, 
        results_detail: null, 

        buckets: [], 
        processing: false, 
        
        machineFilter: '',
    
        result_list: [], 
        seriesState: [], 
        seriesReports: [{
           name: 'Reports',
            data: []
          }], 
        seriesState2:  [{
          data: [44, 55, 41, 64, 22, 43, 21]
        }, {
          data: [53, 32, 33, 52, 13, 44, 32]
        }, {
          data: [53, 32, 33, 52, 13, 44, 32]
        }],

        chartOptions2: {
         colors: ['#003f5c', '#f95d6a', '#ffa600', '#d45087','#2f4b7c', '#665191', '#a05195',   '#ff7c43', ],
         chart: {
          type: 'bar',
          
        },
        title: {
            text: "Results"
        },
        legend: {
          fontSize: '22px',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -10,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false,
          style : {
            fontSize: '22px',
          },

          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return Math.abs(val) + "%"
            }
          }
        },
        yaxis: {
          title: {
              text: 'Percent'
           },
            style : {
            fontSize: '22px',
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Percent'
          },
            style : {
            fontSize: '22px',
          },
          labels: {
            formatter: function (val) {
              return Math.abs(Math.round(val)) + "%"
            }
          }
        },

        },
        chartOptionsProjects: {
         colors: ['#003f5c', '#f95d6a', '#ffa600', '#d45087','#2f4b7c', '#665191', '#a05195',   '#ff7c43', ],
         chart: {
          type: 'bar',
          
        },
        title: {
            text: "Reports"
        },
        legend: {
          fontSize: '22px',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false,
          style : {
            fontSize: '22px',
          },
        },

        xaxis: {
          categories: [],
            style : {
            fontSize: '22px',
          },
          labels: {
   
          }
        },

        },

     
        components: [], 
        db_results: [], 

      }
    },
  computed: {


    machine_list: function() {
      let list = []
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list.sort()
    },
    reporting_list: function() {
      let list = []
      if (this.$store.state.aggregationReporting) {
        Object.entries(this.$store.state.aggregationReporting).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list.sort().reverse()
    },

  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Project types"

    let ref = this


    aggProjectTypes({  }).then( x => {
      if (x.data && x.data.aggregations) {
          this.$store.commit('set_aggregationReporting', x.data.aggregations['Berichtsjahr'].buckets )
          //this.aggreationProjectTypesChart(x.data.aggregations['Projektart'].buckets)
          //this.chartLoaded = true
      }
    }).catch(e => {
      this.$store.dispatch("api_error", e);
    });


  },
  methods: {
      aggreationProjectTypesChart(list) {
        this.seriesState = []
        this.chartOptions.labels = []
        if (list) {
          Object.entries(list).forEach(([key, entry]) => {
           this.seriesState.push(entry["doc_count"])
           this.chartOptions.labels.push(entry["key"])
          })
        }       
      },

      filterStart(btnClick) {

          this.chartLoaded = false

          this.projectKeys = {}
          this.projectList = []
          this.projectSum = []

          this.processing = true


          aggProjectTypesYears({ reportingFilter: this.reportingFilter
                          }).then( x => {

               this.processing = false
               this.seriesReports = []
               this.chartOptionsProjects.xaxis.categories = []

               console.log(x)

               this.seriesState2 = []
               this.chartOptions2.xaxis.categories = []
               if (x.data) {
                 for (let k=0 ; k<x.data.length ; k++) {
                   let prjTypes = {}
                   let prjSum = 0

                   let list = []

                   for (let i=0 ; i<x.data[k].result.aggregations.Projektart.buckets.length ; i++) {

                     console.log()
            
                     list.push(x.data[k].result.aggregations.Projektart.buckets[i]['Bericht'].buckets.length)

                     if (k==0) {
                     //this.chartOptionsProjects.xaxis.categories.push(x.data[k].result.aggregations.Projektart.buckets[i].key)
                     }


                     if (x.data[k].result.aggregations.Projektart.buckets[i].key != "Sonstige") {
                       prjTypes[x.data[k].result.aggregations.Projektart.buckets[i].key] = x.data[k].result.aggregations.Projektart.buckets[i].doc_count
                       prjSum += x.data[k].result.aggregations.Projektart.buckets[i].doc_count
                       this.projectKeys[x.data[k].result.aggregations.Projektart.buckets[i].key] = x.data[k].result.aggregations.Projektart.buckets[i].key
                     }

                   }

                   this.seriesReports.push({ name: x.data[k].year,  data: list })

                   this.projectList.push(prjTypes)
                   this.projectSum.push(prjSum)

                 }




               }    

                for (let k in this.projectKeys) {
                  this.chartOptions2.xaxis.categories.push(k)
                  this.chartOptionsProjects.xaxis.categories.push(k)
                }
               
                 for (let l=0 ; l<this.projectList.length ; l++) {
                   
                   let dd = []

                   for (let k in this.projectKeys) {
                     if (!this.projectList[l][k]) {
                       this.projectList[l][k] = 0
                     }
                     dd.push( ( (this.projectList[l][k] / this.projectSum[l]) *100 ).toFixed(2) )
                   }
                   
                   this.seriesState2.push({name: x.data[l].year, data: dd})

                 }

            this.chartLoaded = true

          })
      
            
          

      },



  }
}

</script>

<style scoped lang="scss">

    .title-sm {
        font-size: 1rem; 
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }

</style>