<template>
  <div>
    <div style="cursor:pointer;"
      class="imgimg-con"
      v-if="image_list.length > 0"
      @click="buttonClicked()"
      
    >
    <v-list-item-icon>
              <v-icon class="imgimg">image</v-icon>
            </v-list-item-icon>
    </div>
  </div>
</template>
  
<script>
import { EventBus } from "@/event-bus";

export default {
  components: {},
  data() {
    return {
      displayValue: "",
    };
  },
  computed: {
    image_list: function () {
      let list = [];
      if (this.cellValue) {
        return this.cellValue.split(";");

        /*
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
        */
      }
      return list;
    },
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      //this.cellValue = this.getValueToDisplay(params);
    },

    buttonClicked(item) {
      EventBus.$emit("img_selected", {
        id: this.params.data.ResultId,
        list: this.image_list,
      });
    },

    getValueToDisplay(params) {
      return params.valueFormatted ? params.valueFormatted : params.value;
    },
  },
};
</script>
  
  <style scoped lang="scss">

  .imgimg-con:hover {
    .imgimg {
    color: rgb(205, 124, 58) !important
  }
  }

</style>