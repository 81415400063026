<template>
  <div class="about wrapper-1080">
    <div class="Aligner">
      <div class="Aligner-item">
        <h2>Welcome, {{ fullname }}</h2>
        <h3>to 8.2 IDAT</h3>
      </div>
    </div> 
  </div>
</template>


<script lang="ts">
import { loadUser } from "@/services/discover.service";

export default {
  data() {
    return {
      fullname: "",
    };
  },
  mounted() {
    this.$store.state.headerTitle = "Welcome";

    loadUser()
      .then((x) => {
        this.fullname = x.data.fullname;
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
};
</script>

<style scoped lang="scss">
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  margin-top: 70px;
}

.Aligner-item {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(155, 155, 155, 1);
  padding: 0 100px;
}
</style>