<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-progress-circular
          style="margin-top: 20px"
          indeterminate
          v-if="processing"
          color="primary"
        ></v-progress-circular>
        <v-card pl-4 style="margin-bottom: 30px" v-if="!processing">
          <div
            style="
              background-color: rgb(205, 124, 58);
              padding: 8px 10px;
              color: #ffffff;
            "
          >false
            8.2 Database
          </div>
          <div class="col col-12">
            <div class="row">
              <div class="py-0 col-6">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">
                          Inspection results
                        </h5>
                        <h2 class="font-weight-regular">
                          {{ total_records.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-6">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Reports</h5>
                        <h2 class="font-weight-regular">
                          {{ db_count_reports.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>

        <v-row v-if="!processing">
          <v-col>
            <v-card class="pa-12" style="height: 600">
              <apexchart
                v-if="chartLoaded"
                type="donut"
                height="500"
                :options="chartOptions"
                :series="seriesState"
              ></apexchart>
            </v-card>
          </v-col>
          <!--
      <v-col>
        
       <v-card class="pa-6" style="height:400px;">
          <apexchart  v-if="chartLoaded" type="bar" height="350" :options="chartOptionsLocations" :series="seriesLocations"></apexchart>
        </v-card>

    
      </v-col>
          -->
        </v-row>

        <v-row v-if="!processing">
          <v-col>
            <v-card class="pa-6" style="height: 900px; margin-top: 10px">
              <v-radio-group v-model="projectTypeChart" row>
                <v-radio label="Reports" value="reports"></v-radio>
                <v-radio label="Results" value="results"></v-radio>
              </v-radio-group>
              <apexchart
                v-if="chartLoaded && projectTypeChart == 'results'"
                type="bar"
                height="800"
                :options="chartOptionsProjects"
                :series="seriesProjects"
              ></apexchart>
              <apexchart
                v-if="chartLoaded && projectTypeChart == 'reports'"
                type="bar"
                height="800"
                :options="chartOptionsProjects2"
                :series="seriesProjectsReports"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!processing">
          <v-col>
            <v-card class="pa-6" style="height: 900px; margin-top: 10px">
              <v-radio-group v-model="machineChart" row>
                <v-radio label="Reports" value="reports"></v-radio>
                <v-radio label="Results" value="results"></v-radio>
              </v-radio-group>
              <apexchart
                v-if="chartLoaded && machineChart == 'results'"
                type="bar"
                height="800"
                :options="chartOptionsMachines"
                :series="seriesMachines"
              ></apexchart>
              <apexchart
                v-if="chartLoaded && machineChart == 'reports'"
                type="bar"
                height="800"
                :options="chartOptionsMachines2"
                :series="seriesMachinesReports"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>

        <v-card pl-4 style="margin-top: 30px" v-if="!processing">
          <div
            style="
              background-color: rgb(205, 124, 58);
              padding: 8px 10px;
              color: #ffffff;
            "
          >
            Search engine
          </div>
          <div class="col col-12">
            <div class="row">
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Results</h5>
                        <h2 class="font-weight-regular">
                          {{ statsData.results.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Machines</h5>
                        <h2 class="font-weight-regular">
                          {{ statsData.machines.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Main components</h5>
                        <h2 class="font-weight-regular">
                          {{ statsData.maincomponents.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>
      </v-container>

      <!--
      <v-flex md6 pa-6 >

      </v-flex>
    
      <v-flex md6 pa-6 >

        <v-card pl-4 class="mb-3"   >
            <div class="col col-12">
                <div class="row">
                    <div  class="py-0  col-sm-6 col-lg-3 col-12">
                        <v-card pl-4 flat >
                            <div class="v-card__text pa-5">
                            <div class="d-flex align-center">
                                <span class="lstick"></span>
                                <div class="ml-4 mr-1">
                                    <h5 class="subtitle-1 grey--text"> Total Results </h5>
                                    <h2 class="font-weight-regular"> {{totalResults}} </h2>
                                </div>
                            </div>
                        </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </v-card>



        <v-card class="pa-6" style="height:550px;margin-top:10px;">
            <apexchart  v-if="chartLoaded" type="bar" height="500" :options="chartOptionsProjects" :series="seriesProjects"></apexchart>
        </v-card>
  
      </v-flex>
      -->
    </v-layout>
  </v-container>
</template>


<script lang="ts">
import { aggreateGeneral, aggreateStats } from "@/services/discover.service";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      totalResults: 0,
      chartLoaded: false,
      seriesState: [],
      db_count_reports: 0,
      total_records: 0,

      processing: false,
      machineChart: "reports",
      projectTypeChart: "reports",

      statsData: {
        machines: {
          count: 0,
        },
        maincomponents: {
          count: 0,
        },
        results: {
          count: 0,
        },
      },

      seriesMachines: [
        {
          name: "Results",
          data: [],
        },
      ],
      seriesMachinesReports: [
        {
          name: "Reports",
          data: [],
        },
      ],
      seriesProjects: [
        {
          name: "Results",
          data: [],
        },
      ],
      seriesProjectsReports: [
        {
          name: "Reports",
          data: [],
        },
      ],
      seriesLocations: [
        {
          name: "Location",
          data: [],
        },
      ],
      chartOptions: {
        colors: [
          "#003f5c",
          "#f95d6a",
          "#ffa600",
          "#d45087",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#ff7c43",
        ],
        chart: {
          type: "donut",
          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Inspection results",
        },
        legend: {
          position: "bottom",
        },

        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsMachines: {
        colors: [
          "#003f5c",
          "#f95d6a",
          "#ffa600",
          "#d45087",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#ff7c43",
        ],
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Machine - Inspection results",
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsMachines2: {
        colors: [
          "#003f5c",
          "#f95d6a",
          "#ffa600",
          "#d45087",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#ff7c43",
        ],
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Machine - Reports",
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsProjects: {
        colors: [
          "#003f5c",
          "#f95d6a",
          "#ffa600",
          "#d45087",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#ff7c43",
        ],
        chart: {
          type: "bar",

          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Project type - Results",
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsProjects2: {
        colors: [
          "#003f5c",
          "#f95d6a",
          "#ffa600",
          "#d45087",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#ff7c43",
        ],
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Project type - Reports",
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      chartOptionsLocations: {
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
        },
        title: {
          text: "Location",
        },
        tooltip: {
          shared: true,
          intersect: false,
          style: {
            fontSize: "22px",
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
      buckets: [],

      filterState: "",

      inspector_list: [],
      projectId: null,
      selInspector: null,
    };
  },
  computed: {
    aggreationStatus: function () {
      return this.$store.state.aggregationStates;
    },
    aggreationMachines: function () {
      return this.$store.state.aggregationMachines;
    },
    aggreationProjects: function () {
      return this.$store.state.aggregationProjects;
    },
    aggreationLocations: function () {
      return this.$store.state.aggregationLocations;
    },
    state_list: function () {
      let list = [];
      if (this.$store.state.aggregationStates) {
        Object.entries(this.$store.state.aggregationStates).forEach(
          ([key, entry]) => {
            if (entry["key"].length < 2) {
              list.push("Ohne Zuordnung");
            } else {
              list.push(entry["key"]);
            }
          }
        );
      }
      return list;
    },
    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list;
    },
    project_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationProjects).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list;
    },
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Overview";

    let ref = this;
    this.chartOptions.chart.events = {
      click: function (event, chartContext, config) {
        if (config.seriesIndex >= 0) {
          ref.selectChartItem(
            config.config.series[config.seriesIndex].name,
            config.config.xaxis.categories[config.seriesIndex],
            config.config.series[config.seriesIndex].data[config.dataPointIndex]
          );
        }
      },
    };

    this.processing = true;

    aggreateGeneral({})
      .then((x) => {
        this.processing = false;
        if (x.data && x.data.aggregations) {
          this.$store.commit(
            "set_aggregationStates",
            x.data.aggregations["Status"].buckets
          );
          this.$store.commit(
            "set_aggregationMachines",
            x.data.aggregations["Anlagentyp"].buckets
          );
          this.$store.commit(
            "set_aggregationMachinesReports",
            x.data.aggregations["ProjekAnlagenBerichte"].buckets
          );
          this.$store.commit(
            "set_aggregationProjects",
            x.data.aggregations["ProjekartBerichte"].buckets
          );
          this.$store.commit(
            "set_aggregationLocations",
            x.data.aggregations["Standort"].buckets
          );

          if (x.data.aggregations["Bericht"]) {
            this.db_count_reports =
              x.data.aggregations["Bericht"].buckets.length;
          }

          this.aggreationStateChart();
          this.aggreationMachinesChart();
          this.aggreationMachinesReportsChart();
          this.aggreationProjectsChart();
          this.aggreationProjectsReportsChart();
          this.aggreationLocationsChart();
          this.chartLoaded = true;
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });

    aggreateStats({})
      .then((x) => {

        if (x && x.data) {
          this.statsData = x.data
          console.log(x.data)
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  methods: {
    selectChartItem(name, cat, data) {
      console.log(name, cat, data);
    },

    aggreationStateChart() {
      this.seriesState = [];
      this.chartOptions.labels = [];

      this.total_records = 0;

      if (this.$store.state.aggregationStates) {
        Object.entries(this.$store.state.aggregationStates).forEach(
          ([key, entry]) => {
            this.seriesState.push(entry["doc_count"]);
            this.chartOptions.labels.push(entry["key"]);
            this.total_records += entry["doc_count"];
          }
        );
      }
    },
    aggreationProjectsChart() {
      this.seriesProjects[0].data = [];

      let i = 0;

      if (this.$store.state.aggregationProjects) {
        Object.entries(this.$store.state.aggregationProjects).forEach(
          ([key, entry]) => {
            if (i < 20) {
              this.seriesProjects[0].data.push(entry["doc_count"]);
              this.chartOptionsProjects.xaxis.categories.push(entry["key"]);
            }
            i++;
          }
        );
      }
    },
    aggreationProjectsReportsChart() {
      this.seriesProjectsReports[0].data = [];

      let i = 0;

      if (this.$store.state.aggregationProjects) {
        Object.entries(this.$store.state.aggregationProjects).forEach(
          ([key, entry]) => {
            if (i < 20) {
              this.seriesProjectsReports[0].data.push(
                entry["projects"].buckets.length
              );
              this.chartOptionsProjects2.xaxis.categories.push(entry["key"]);
            }
            i++;
          }
        );
      }
    },
    aggreationLocationsChart() {
      this.seriesLocations[0].data = [];

      let i = 0;

      if (this.$store.state.aggregationLocations) {
        Object.entries(this.$store.state.aggregationLocations).forEach(
          ([key, entry]) => {
            if (i < 20) {
              this.seriesLocations[0].data.push(entry["doc_count"]);
              this.chartOptionsLocations.xaxis.categories.push(entry["key"]);
            }
            i++;
          }
        );
      }
    },
    aggreationMachinesReportsChart() {
      this.seriesMachinesReports[0].data = [];

      let i = 0;

      if (this.$store.state.aggregationMachinesReports) {
        Object.entries(this.$store.state.aggregationMachinesReports).forEach(
          ([key, entry]) => {
            if (i < 30) {
              this.seriesMachinesReports[0].data.push(
                entry["projects"].buckets.length
              );
              this.chartOptionsMachines2.xaxis.categories.push(entry["key"]);
            }
            i++;
          }
        );
      }
    },
    aggreationMachinesChart() {
      this.seriesMachines[0].data = [];

      let i = 0;

      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            if (i < 30) {
              this.seriesMachines[0].data.push(entry["doc_count"]);
              this.chartOptionsMachines.xaxis.categories.push(entry["key"]);
            }
            i++;
          }
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>