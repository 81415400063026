<template>
 <v-container fluid fill-height style="background-color:#f4f6f9;">
    <v-layout>
        
      <v-flex md3 pa-3>
        <v-card style="background-color:#FAFBFD;"    >
            <div style="background-color:rgb(205, 124, 58);padding: 8px 10px;color:#ffffff;">
              Filter
            </div>
            <v-row style="padding:12px;">
            <v-col cols="12"  >
   
                <v-combobox
                v-model="expertFilter"
                :items="experts_list"
                label="Inspectors"
                ></v-combobox>

                <v-text-field
                    v-model="machineKeyword"
                    v-show="switchMachine"
                    label="Machine (Wildcard)"
                  ></v-text-field>
                  <v-combobox
                    v-model="machineFilter"
                    v-show="!switchMachine"
                    :items="machine_list"
                    
                    multiple
                    small-chips
                    auto-select-first
                    chips
                    clearable
                    deletable-chips
                    label="Machine"
                  ></v-combobox>
                  <v-switch
                    style="margin-top: 0px"
                    v-model="switchMachine"
                    :label="`Wildcard`"
                  ></v-switch>


                <v-combobox
                v-model="reportingFilter"
                :items="reporting_list"
                multiple
                label="Reporting year"
                ></v-combobox>
   
                <v-btn block @click="filterStart(true)">
                    Search
                </v-btn>

                      <v-progress-circular
                          style="margin-top:20px;"
                          indeterminate
                          v-if="processing"
                          color="primary"
                        ></v-progress-circular>
            </v-col>
            </v-row>

        </v-card> 
      </v-flex>
      <v-flex md9 pa-3 >

        <v-card pl-4    >
            <div class="col col-12">
                <div class="row">
                    <div  class="py-0  col-6">
                        <v-card pl-4 flat >
                            <div class="v-card__text pa-5">
                            <div class="d-flex align-center">
                                <span class="lstick"></span>
                                <div class="ml-4 mr-1">
                                    <h5 class="subtitle-1 grey--text"> Components </h5>
                                    <h2 class="font-weight-regular"> {{components_result.length}} </h2>
                                </div>
                            </div>
                          </div>
                        </v-card>

                    </div>
                    <div  class="py-0  col-6">
                        <v-card pl-4 flat >
                            <div class="v-card__text pa-5">
                            <div class="d-flex align-center">
                                <span class="lstick"></span>
                                <div class="ml-4 mr-1">
                                    <h5 class="subtitle-1 grey--text"> Reports </h5>
                                    <h2 class="font-weight-regular"> {{db_reports_result.length}} </h2>
                                </div>
                            </div>
                          </div>
                        </v-card>

                    </div>
                </div>
            </div>
        </v-card>



        <v-card v-show="db_components_result.length>0" pl-4   style="margin-top:10px;">
            <div style="padding:6px 20px">
         <v-text-field
            v-model="searchkey"
            label="Search"
            clearable
          ></v-text-field>
          </div>

            <v-simple-table
                fixed-header
                height="70vh"
            >
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left cursor-pointer" @click="sortBy('key')">
                        Component
                    </th>
                    <th class="text-left cursor-pointer" @click="sortBy('doc_count')">
                        Inspection results
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in components_result"
                    :key="item.key"
                    >
                    <td>{{ item.key }}</td>
                    <td>{{ item.doc_count  }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>


        </v-card>

      </v-flex>
    </v-layout>


  <!--
    -->









  </v-container>
</template>


<script lang="ts">



import { filterExperts, aggregateExperts } from '@/services/discover.service';  
import moment from 'moment';

export default  {
    components: {
     
    },
    data () {
      return {
        dialogResults: false, 
        chartLoaded: false, 
        expertFilter: null, 
        reportingFilter: null, 
        db_reports_result: [], 
        processing: false, 
        searchkey: null, 
        mapSort: {}, 
        db_components_result: [], 
        rows: [],
        machineKeyword: null,
        switchMachine: false,
        machineFilter: "",
      }
    },
  computed: {
    total_results() {
        return this.db_components_result.length
    },
    components_result() {
        
        if (this.searchkey && this.searchkey.length > 2) {
            return this.db_components_result.filter((item) => {
                return item.key.toLowerCase().indexOf(this.searchkey.toLowerCase()) !== -1
            });
        }
        
        return this.db_components_result;
    },

    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort();
    },

    experts_list: function() {
      let list = []
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationExperts).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list.sort()
    },

    reporting_list: function() {
      let list = []
      if (this.$store.state.aggregationReporting) {
        Object.entries(this.$store.state.aggregationReporting).forEach(([key, entry]) => {
          list.push(entry["key"])
        })
      }
      return list.sort().reverse()
    },
   

  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Inspectors"

    let ref = this

    aggregateExperts({  }).then( x => {
      if (x.data && x.data.aggregations) {
          console.log( x.data.aggregations)
          this.$store.commit(
            "set_aggregationMachines",
            x.data.aggregations["Anlagentyp"].buckets
          );
          this.$store.commit('set_aggregationExperts', x.data.aggregations['Verantwortlich'].buckets )
          this.$store.commit('set_aggregationReporting', x.data.aggregations['Berichtsjahr'].buckets )
      }
    }).catch(e => {
      this.$store.dispatch("api_error", e);
    });

    

  },
  methods: {
      sortByKey (key, idx) {
        if (idx == -1) {
          return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
        }
        else {
          return (a, b) => (a[key] > b[key]) ? -1 : ((b[key] > a[key]) ? 1 : 0);
        }

      },
      sortBy(val) {

        if (!this.mapSort[val]) {
            this.mapSort[val] = 1
        }
        else {
            this.mapSort[val] = this.mapSort[val]*-1
        }

        this.db_components_result = this.db_components_result.sort(this.sortByKey(val, this.mapSort[val])) 
      },

      filterStart(btnClick) {

           this.chartLoaded = false
           if ( this.expertFilter ) {
               console.log("expertFilter", this.expertFilter) 

            
              filterExperts({  expertFilter: this.expertFilter,
                               reportingFilter: this.reportingFilter,
                               machineFilter: this.machineFilter,
                               machineKeyword: this.machineKeyword,
                               switchMachine: this.switchMachine, 
                             }).then( x => {

                    console.log(x)

                    if (x.data.aggregations['Bauteil']) {
                        this.db_components_result = x.data.aggregations['Bauteil'].buckets
                    }
                    if (x.data.aggregations['Bericht']) {
                        this.db_reports_result = x.data.aggregations['Bericht'].buckets
                    }
              })
           
              
           }
           else {
             alert("No inspector selected")
           }

      },



  }
}

</script>

<style  lang="scss">

    .title-sm {
        font-size: 1rem; 
    }

  .v-sheet--offset {
    top: -24px;
    position: relative;
  }



.list .row {
  display: flex;
}

.list .row .data-cell,
.data-header-cell {
  position: relative !important;
  width: auto !important;
}

revogr-focus,
.selection-border-range,
.edit-input-wrapper {
  width: 100% !important;
}


</style>