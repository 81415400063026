<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Projects</td>
                      <td>
                        <a href="https://data.8p2.de/help-center/8p2data_projects.mp4" target="_blank" style="text-decoration: none">
                          <v-btn small depressed color="primary"> Show </v-btn>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>DataGrid</td>
                      <td>
                        <a href="https://data.8p2.de/help-center/8p2data_datgrid.mp4"  target="_blank" style="text-decoration: none">
                          <v-btn small depressed color="primary"> Show </v-btn>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>


<script lang="ts">
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Help center";
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>