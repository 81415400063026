import * as axios from 'axios';
import qs from 'qs';

function getAllUsers(data) {
    const url = `/adminarea/users`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function deleteUser(data) {
    const url = `/adminarea/delete-user`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


function saveUser(data) {
    const url = `/adminarea/update-user`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function adminStats(data) {
    const url = `/adminarea/admin-stats`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}




function contractorStats(data) {
    const url = `/adminarea/contractor-stats`;
    return axios.post(url, data)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}


export {
    getAllUsers,
    saveUser,
    deleteUser,
    adminStats,
    contractorStats
}