<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-progress-circular
          style="margin-top: 20px"
          indeterminate
          v-if="processing"
          color="primary"
        ></v-progress-circular>

        <v-card pl-4 style="margin-top: 30px" v-if="!processing">
          <div
            style="
              background-color: rgb(205, 124, 58);
              padding: 8px 10px;
              color: #ffffff;
            "
          >
            8.2 Database
          </div>
          <div class="col col-12">
            <div class="row">
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Projects</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.projects.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Inspections</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.inspections.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Results</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.results.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Contractors</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.contractors.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">User</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.users.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Checklists</h5>
                        <h2 class="font-weight-regular">
                          {{ statsDbData.checklists.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Import Queue</h5>
                        <h2 class="font-weight-regular">
                          {{ queueDbData.import_finished }}/{{ queueDbData.import_all }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Transfer Queue</h5>
                        <h2 class="font-weight-regular">
                          {{ queueDbData.transfer_finished }}/{{ queueDbData.transfer_all }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>

        <v-card pl-4 style="margin-top: 30px" v-if="!processing">
          <div
            style="
              background-color: rgb(205, 124, 58);
              padding: 8px 10px;
              color: #ffffff;
            "
          >
            Search engine
          </div>
          <div class="col col-12">
            <div class="row">
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Results</h5>
                        <h2 class="font-weight-regular">
                          {{ statsElasticData.results.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Main components</h5>
                        <h2 class="font-weight-regular">
                           {{ statsElasticData.maincomponents.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Machines</h5>
                        <h2 class="font-weight-regular">
                          {{ statsElasticData.machines.count.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>

        <v-card pl-4 style="margin-top: 30px" v-if="!processing">
          <div
            style="
              background-color: rgb(205, 124, 58);
              padding: 8px 10px;
              color: #ffffff;
            "
          >
            Contractor
          </div>
          <v-row style="padding: 12px">
            <v-col cols="6" style="padding-right: 40px">
              <v-combobox
                v-model="contractorFilter"
                :items="contractor_list"
                label="Contractor"
              ></v-combobox>

              <v-btn block @click="searchContractor()"> Search </v-btn>

              <v-progress-circular
                style="margin-top: 20px"
                indeterminate
                v-if="processing2"
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-simple-table style="margin-top: 30px" v-if="contractorData">
            <template v-slot:default>
              <thead></thead>
              <tbody>
                <tr>
                  <td>DB - User</td>
                  <td>{{ contractorData.db.users.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>DB - Projects</td>
                  <td>{{ contractorData.db.projects.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>DB - Inspections</td>
                  <td>{{ contractorData.db.inspections.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>DB - Results</td>
                  <td>{{ contractorData.db.results.toLocaleString() }}</td>
                </tr>

                <tr>
                  <td>Search Engine - Results</td>
                  <td>
                    {{ contractorData.elastic.results.count.toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td>Search Engine - Machines</td>
                  <td>
                    {{ contractorData.elastic.machines.count.toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <td>Search Engine - Main components</td>
                  <td>
                    {{ contractorData.elastic.machines.count.toLocaleString() }}
                  </td>
                </tr>

                <tr>
                  <td>Import Queue - Wait</td>
                  <td>{{ importData.ip_wait.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>Import Queue - Finished</td>
                  <td>{{ importData.ip_finished.toLocaleString() }}</td>
                </tr>

                <tr>
                  <td>Transfer Queue - Wait</td>
                  <td>{{ transferData.ip_wait.toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>Transfer Queue - Finished</td>
                  <td>{{ transferData.ip_finished.toLocaleString() }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-container>
    </v-layout>
  </v-container>
</template>


<script lang="ts">
import { adminStats, contractorStats } from "@/services/adminarea.service";

export default {
  components: {},
  data() {
    return {
      totalResults: 0,
      chartLoaded: false,
      seriesState: [],
      db_count_reports: 0,
      total_records: 0,

      processing: false,
      contractorFilter: null,
      processing2: false,
      machineChart: "reports",
      projectTypeChart: "reports",
      contractor_list: [],
      contractorData: null,

      queueDbData: {
        import_all: 0,
        import_finished: 0,
        transfer_all: 0,
        transfer_finished: 0,
      },

      statsDbData: {
        inspections: 0,
        projects: 0,
        results: 0,
        contractors: 0,
        checklists: 0,
        users: 0,
      },

      importData: {
        finished: 0,
        wait: 0,
      },

      transferData: {
        finished: 0,
        wait: 0,
      },

      statsElasticData: {
        machines: {
          count: 0,
        },
        maincomponents: {
          count: 0,
        },
        results: {
          count: 0,
        },
      },

      filterState: "",

      inspector_list: [],
      projectId: null,
      selInspector: null,
    };
  },
  computed: {},
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Admin Dashboard";

    let ref = this;

    this.processing = true;

    adminStats({})
      .then((x) => {
        if (x && x.data) {
          console.log(x.data);

          this.statsDbData = x.data.db;
          this.statsElasticData = x.data.elastic;
          this.contractor_list = x.data.contractors;
          this.queueDbData = x.data.queue;

          this.processing = false;
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  methods: {
    searchContractor: function () {
      if (this.contractorFilter) {
        contractorStats({ contractor: this.contractorFilter }).then((x) => {
          this.contractorData = x.data;

          this.importData["ip_wait"] = x.data.importqueue.ip_wait;
          this.importData["ip_finished"] = x.data.importqueue.ip_finished;

          this.transferData = x.data.transferqueue;
          console.log("data", x.data);
        });
      }

      /*

        */
    },
  },
};
</script>

<style scoped lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>