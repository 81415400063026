<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <div
                style="
                  background-color: rgb(205, 124, 58);
                  padding: 8px 10px;
                  color: #ffffff;
                "
              >
                Filter
              </div>
              <v-row style="padding: 12px">
                <v-col cols="6" style="padding-right: 40px">
                  <v-text-field
                    v-model="machineKeyword"
                    v-show="switchMachine"
                    label="Machine (Wildcard)"
                  ></v-text-field>
                  <v-combobox
                    v-model="machineFilter"
                    v-show="!switchMachine"
                    :items="machine_list"
                    multiple
                    small-chips
                    auto-select-first
                    chips
                    clearable
                    deletable-chips
                    label="Machine"
                  ></v-combobox>
                  <v-switch
                    style="margin-top: 0px"
                    v-model="switchMachine"
                    :label="`Wildcard`"
                  ></v-switch>
                  <v-combobox
                    v-model="reportingFilter"
                    :items="reporting_list"
                    multiple
                    label="Reporting year"
                  ></v-combobox>

                  <v-btn block @click="filterStart(true)"> Search </v-btn>

                  <v-progress-circular
                    style="margin-top: 20px"
                    indeterminate
                    v-if="processing"
                    color="primary"
                  ></v-progress-circular>
                </v-col>

                <v-col cols="6">
                  <div style="width:100%;text-align: right;">
                    <v-btn small text clearable @click="tableSettings()">
                      Table Settings
                    </v-btn>
                  </div>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Machines</h5>
                        <h2 class="font-weight-regular">
                          {{ totalResults.toLocaleString() }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              v-show="result_list.length > 0"
              pl-4
              style="margin-top: 10px"
            >
              <ag-grid-vue
                style="width: 100%"
                v-bind:style="{ height: tableH + 'px' }"
                class="ag-theme-alpine"
                :enableRangeSelection="true"
                :columnDefs="columnDefs"
                :enableCharts="true"
                :gridOptions="gridOptions"
                :defaultColDef="defaultColDef"
                :sideBar="sideBar"
                :rowData="rowData"
              >
              </ag-grid-vue>

              <v-simple-table fixed-header height="70vh" v-if="showCustomTable">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('siteName')"
                        style="cursor: pointer"
                      >
                        Site
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('Hersteller')"
                        style="cursor: pointer"
                      >
                        Manufacturer
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('machinetypeNameCat')"
                        style="cursor: pointer"
                      >
                        Machine
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('machine_serialnumber')"
                        style="cursor: pointer"
                      >
                        Serial number
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('reportDate')"
                        style="cursor: pointer"
                      >
                        Report date
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('commissioningDate')"
                        style="cursor: pointer"
                      >
                        Commissioning date
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('operating_hours')"
                        style="cursor: pointer"
                      >
                        Operating hours
                      </th>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('earnings')"
                        style="cursor: pointer"
                      >
                        Yield
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in result_list" :key="item.key">
                      <td>{{ item.siteName }}</td>
                      <td>{{ item.Hersteller }}</td>
                      <td>{{ item.machinetypeNameCat }}</td>
                      <td>{{ item.machine_serialnumber }}</td>
                      <td>{{ item.reportDate }}</td>
                      <td>{{ item.commissioningDate }}</td>
                      <td>
                        {{
                          item.operating_hours
                            ? item.operating_hours.toLocaleString()
                            : "n/a"
                        }}
                      </td>
                      <td>
                        {{
                          item.earnings
                            ? Math.round(item.earnings).toLocaleString()
                            : "n/a"
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
    <TableSettings
      @closed="tableSettingsClosed"
      v-if="showTableSettings"
      :tableState="tableState"
      @changeTblState="changeTblState"
      category="machines"
    ></TableSettings>
  </v-container>
</template>


<script lang="ts">
import {
  filterMachineService,
  aggreateMachines,
} from "@/services/discover.service";
import moment from "moment";
import TableSettings from "../components/TableSettings";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
    TableSettings,
  },
  data() {
    return {
      dialogResults: false,
      totalResults: 0,
      chartLoaded: false,
      reportingFilter: null,
      tableState: {},
      showTableSettings: false,
      results_detail: null,
      offset: 0,
      limit: 500,
      loading: false,
      has_more: false,
      mapSort: {},
      machineKeyword: null,
      reportCount: 0,
      switchMachine: false,

      machineFilter: "",

      processing: false,
      result_list: [],

      components: [],
      db_results: [],

      columnDefs: null,
      defaultColDef: null,
      rowData: [],
      gridOptions: null,
      tableH: 300,
      showCustomTable: false,
      sideBar: "columns",
      rows: [],
    };
  },
  computed: {
    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort();
    },
    reporting_list: function () {
      let list = [];
      if (this.$store.state.aggregationReporting) {
        Object.entries(this.$store.state.aggregationReporting).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort().reverse();
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  beforeMount() {
    this.gridOptions = {
      // EVENTS
      // Add event handlers
      onCellClicked: (event) =>
        function (x) {
          console.log(x);
        },
      //onColumnResized: event => console.log('A column was resized'),
      onGridReady: (event) => console.log("The grid is now ready"),
    };

    this.resizeWindow();

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      autoHeight: true,
      editable: true,
      filter: "agTextColumnFilter",
    };
    this.columnDefs = [
      { headerName: "Site", field: "siteName", sortable: true, filter: true },

      {
        headerName: "Manufacturer",
        field: "Hersteller",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Machine",
        field: "machinetypeNameCat",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Serial number",
        field: "machine_serialnumber",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Report date",
        field: "reportDate",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Commissioning date",
        field: "commissioningDate",
        sortable: true,
        filter: true,
      },

      {
        headerName: "Operating hours",
        field: "operating_hours",
        sortable: true,
      },
      { headerName: "Yield", field: "earnings", sortable: true },
    ];
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Machines";

    let ref = this;

    // Detect when scrolled to bottom.
    const listElm = document.querySelector(".v-data-table__wrapper");
    if (listElm) {
      listElm.addEventListener("scroll", (e) => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.loadMore();
        }
      });
    }

    this.resizeWindow();

    aggreateMachines({})
      .then((x) => {
        if (x.data && x.data.aggregations) {
          //console.log( x.data.aggregations)

          this.processing = false;
          this.$store.commit(
            "set_aggregationMachines",
            x.data.aggregations["Anlagentyp"].buckets
          );
          this.$store.commit(
            "set_aggregationReporting",
            x.data.aggregations["Berichtsjahr"].buckets
          );
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  methods: {
    changeTblState(tblstate) {
      if (tblstate) {
        this.gridOptions.columnApi.setColumnState(tblstate.colState);
        this.gridOptions.columnApi.setColumnGroupState(tblstate.groupState);
        this.gridOptions.api.setSortModel(tblstate.sortState);
        this.gridOptions.api.setFilterModel(tblstate.filterState);
      }
    },
    tableSettings() {
      this.tableState = {
        colState: this.gridOptions.columnApi.getColumnState(),
        groupState: this.gridOptions.columnApi.getColumnGroupState(),
        sortState: this.gridOptions.api.getSortModel(),
        filterState: this.gridOptions.api.getFilterModel(),
      };
      this.showTableSettings = true;
    },
    tableSettingsClosed() {
      this.showTableSettings = false;
    },
    resizeWindow(e) {
      this.tableH = window.outerHeight - 500;
    },
    loadMore() {
      console.log("this.has_more", this.has_more);
      if (this.has_more) {
        this.offset++;
        this.filterStart(null);
      }
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },
    sortBy(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.result_list = this.result_list.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    filterStart(btnClick) {
      this.chartLoaded = false;

      if ( (!this.switchMachine && this.machineFilter && this.machineFilter.length>0) ||
           (this.switchMachine && this.machineKeyword && this.machineKeyword.length>1) ) {
        //console.log("machineFilter", this.machineFilter)

  
        if (btnClick) {
          this.result_list = [];
          this.offset = 0;
          this.has_more = true;
        }

        //let reportMap = {}
        //this.reportCount = 0

        this.processing = true;

        filterMachineService({
          machineFilter: this.machineFilter,
          machineKeyword: this.machineKeyword,
          switchMachine: this.switchMachine, 
          reportingFilter: this.reportingFilter,
          offset: this.offset,
          limit: this.limit,
        }).then((x) => {
          this.processing = false;
          this.totalResults = x.data.hits.total.value;

          //this.result_list = []

          for (let k = 0; k < x.data.hits.hits.length; k++) {
            /*
                      if (!reportMap[x.data.hits.hits[k]._source['report_id']]) {
                        reportMap[x.data.hits.hits[k]._source['report_id']] = true
                        this.reportCount++
                      }
                      */

            if (x.data.hits.hits[k]._source["commissioningDate"]) {
              x.data.hits.hits[k]._source["commissioningDate"] = moment(
                x.data.hits.hits[k]._source["commissioningDate"]
              ).format("DD.MM.YYYY");
            }

            if (x.data.hits.hits[k]._source["reportDate"]) {
              x.data.hits.hits[k]._source["reportDate"] = moment(
                x.data.hits.hits[k]._source["reportDate"]
              ).format("DD.MM.YYYY");
            }

            this.result_list.push(x.data.hits.hits[k]._source);
          }

          this.rowData = this.result_list;

          this.has_more = x.data.hits.hits.length > 0;
        });
      } else {
        alert("No machine selected");
      }
    },
  },
};
</script>

<style  lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.list .row {
  display: flex;
}

.list .row .data-cell,
.data-header-cell {
  position: relative !important;
  width: auto !important;
}

revogr-focus,
.selection-border-range,
.edit-input-wrapper {
  width: 100% !important;
}
</style>