<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-card pl-4 style="margin-bottom: 30px" v-if="!processing">
          <div class="col col-12">
            <div class="row">
              <div class="py-0 col-4">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Projects</h5>
                        <h2 class="font-weight-regular">
                          {{ project_list.length }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="py-0 col-8">
                <v-card pl-4 flat>
                  <div class="v-card__text pa-5">
                    <div class="d-flex align-center">
                      <span class="lstick"></span>
                      <div class="ml-4 mr-1">
                        <h5 class="subtitle-1 grey--text">Contractor</h5>
                        <h2 class="font-weight-regular">
                          {{ contractorList }}
                        </h2>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>

        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <!--
              <v-row
                justify="end"
                style="padding: 50px 30px"
                v-if="!processing && project_list && project_list.length > 0"
              >
              
                <v-btn
                  v-if="importCount < 1"
                  depressed
                  color="primary"
                  @click="importState('start')"
                >
                  Start import
                </v-btn>
               
              </v-row>
               -->
              <v-progress-circular
                style="margin: 40px"
                indeterminate
                v-if="processing"
                color="primary"
              ></v-progress-circular>

              <div style="padding: 6px 20px">
                <v-text-field
                  v-model="searchkey"
                  label="Search"
                  clearable
                ></v-text-field>
              </div>

              <div
                style="padding: 30px"
                v-if="!processing && project_list && project_list.length == 0"
              >
                No projects.
              </div>

              <v-simple-table
                v-if="!processing && project_list && project_list.length > 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left" @click="sort('projectNumber')">
                        Number
                      </th>
                      <th class="text-left" @click="sort('site')">Site</th>
                      <th class="text-left" @click="sort('projectname')">
                        Name
                      </th>
                      <th class="text-left" @click="sort('contractorName')">
                        Contractor
                      </th>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in project_list" :key="item.uid">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.projectNumber }}</td>
                      <td>{{ item.site }}</td>
                      <td>{{ item.projectname }}</td>
                      <td>{{ item.contractorName }}</td>
                      <td>
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.inspections == 'wait'
                          "
                          >hourglass_empty</v-icon
                        >
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.inspections == 'run'
                          "
                          >scatter_plot</v-icon
                        >
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.inspections == 'finished'
                          "
                          >verified</v-icon
                        >
                      </td>

                      <td>
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.results == 'wait'
                          "
                          >hourglass_empty</v-icon
                        >
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.results == 'run'
                          "
                          >scatter_plot</v-icon
                        >
                        <v-icon
                          v-if="
                            item.project_state &&
                            item.project_state.results == 'finished'
                          "
                          >verified</v-icon
                        >
                      </td>

                      <td>
                        <v-progress-circular
                          indeterminate
                          v-if="item.processing"
                          color="primary"
                        ></v-progress-circular>
                        <v-btn
                          v-if="
                            !item.project_state ||
                            (item.project_state &&
                              item.project_state.inspections == 'finished' &&
                              item.project_state.results == 'finished' &&
                              !item.processing)
                          "
                          @click="resetRow(item)"
                          small
                          depressed
                          color="warning"
                        >
                          Restart
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <!--
              <v-row
                justify="end"
                style="padding: 50px 30px"
                v-if="!processing"
              >
                <v-btn
                  v-if="importCount > 0"
                  depressed
                  color="warning"
                  @click="importState('stop')"
                >
                  Remove all data
                </v-btn>
              </v-row>
              -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>


<script lang="ts">
import {
  getMyProjects,
  removeProjects,
  resetProject,
  importProjects,
} from "@/services/discover.service";
import moment from "moment";

export default {
  components: {},
  data() {
    return {
      showDetail: false,
      selectedRow: {},
      processing: true,
      importCount: 0,
      currentSort: "name",
      currentSortDir: "asc",
      contractorList: "",
      searchkey: null,
      projectList: [], 
    };
  },
  computed: {

    
    project_list() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.projectList.filter((item) => {
          return (
            item.projectname
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1 ||
            item.site.toLowerCase().indexOf(this.searchkey.toLowerCase()) !== -1 ||
            item.projectNumber
              .toString()
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1
          );
        });
      }

      return this.$store.state.projectList;
    },
 
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - My projects";
    this.loadProjects();
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;

      this.projectList = this.project_list.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    sortBy(key) {
      console.log("sort byl ", key);
    },
    resetRow(item) {
      item.processing = true;
      resetProject(item)
        .then((x) => {
          if (!item.project_state) {
            item.project_state = {};
          }

          item.project_state.inspections = "wait";
          item.project_state.results = "wait";
          item.processing = false;
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    },
    loadProjects() {
      this.processing = true;
      getMyProjects({})
        .then((x) => {
          console.log(x.data);
          this.$store.commit("set_projectList", x.data.projects);
          this.importCount = x.data.importCount;

          this.projectList = x.data.projects

          this.contractorList = x.data.contractorNames.join(",");
          this.processing = false;
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    },
    resultDetailClosed() {
      this.showDetail = false;
    },
    importState(val) {
      this.processing = true;
      if (val == "stop") {
        let text;
        if (confirm("Should all project data really be removed? ") == true) {
          removeProjects().then((x) => {
            this.loadProjects();
          });
        } else {
          this.processing = false;
        }
      } else {
        importProjects().then((x) => {
          this.loadProjects();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>