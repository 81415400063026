<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <v-row justify="end" style="padding: 30px 30px">
                <v-btn depressed color="primary" @click="selectRow(null)">
                  Add user
                </v-btn>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">E-Mail</th>
                      <th class="text-left">Company</th>
                      <th class="text-left">Contractors</th>
                      <th class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in user_list" :key="item.uid">
                      <td>{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.company }}</td>
                      <td>{{ item.contractors.length }}</td>
                      <td style="text-align: right">
                        <v-btn
                          @click="selectRow(item)"
                          small
                          depressed
                          color="primary"
                        >
                          Edit
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
    <UserDetail
      @closed="resultDetailClosed"
      v-if="showDetail"
      :selectedRow="selectedRow"
    ></UserDetail>
  </v-container>
</template>


<script lang="ts">
import { getAllUsers } from "@/services/adminarea.service";
import moment from "moment";

import UserDetail from "../components/UserDetail";

export default {
  components: {
    UserDetail,
  },
  data() {
    return {
      showDetail: false,
      selectedRow: {},
    };
  },
  computed: {
    user_list: function () {
      return this.$store.state.userList;
    },
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Accounts";

    let ref = this;

    getAllUsers({})
      .then((x) => {
        this.$store.commit("set_userList", x.data.list);
        this.$store.commit("set_contractorList", x.data.contractors);
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  methods: {
    resultDetailClosed() {
      this.showDetail = false;
    },
    selectRow(item) {
      this.selectedRow = item;
      this.showDetail = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>