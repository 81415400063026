<template>
  <v-container fluid fill-height style="background-color: #f4f6f9">
    <v-layout>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card style="background-color: #fafbfd">
              <div
                style="
                  background-color: rgb(205, 124, 58);
                  padding: 5px 10px;
                  color: #ffffff;
                "
              >
                Filter
              </div>
              <v-row style="padding: 12px">
                <v-col cols="6" style="padding-right: 30px">
                  <v-text-field
                    v-model="machineKeyword"
                    v-show="switchMachine"
                    label="Machine (Wildcard)"
                  ></v-text-field>
                  <v-combobox
                    v-model="machineFilter"
                    v-show="!switchMachine"
                    :items="machine_list"
                    multiple
                    label="Machine"
                  ></v-combobox>
                  <v-switch
                    style="margin-top: 0px"
                    v-model="switchMachine"
                    :label="`Wildcard`"
                  ></v-switch>
                  <v-btn block @click="filterStart(true)"> Search </v-btn>
                  <v-progress-circular
                    style="margin-top: 20px"
                    indeterminate
                    v-if="processing"
                    color="primary"
                  ></v-progress-circular>
                </v-col>

                <v-col cols="6">
                  <v-row>
                    <v-col cols="6">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <span class="lstick"></span>
                          <div class="ml-4 mr-1">
                            <h5 class="subtitle-1 grey--text">Components</h5>
                            <h2 class="font-weight-regular">
                              {{ total_results.toLocaleString() }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="6">
                      <div class="v-card__text pa-5">
                        <div class="d-flex align-center">
                          <span class="lstick"></span>
                          <div class="ml-4 mr-1">
                            <h5 class="subtitle-1 grey--text">Reports</h5>
                            <h2 class="font-weight-regular">
                              {{ reports_count.toLocaleString() }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <v-card pl-4 v-show="db_result.length > 0" style="margin-top: 30px">
              <div style="padding: 6px 20px">
                <v-text-field
                  v-model="searchkey"
                  label="Search"
                  clearable
                
                ></v-text-field>
              </div>

              <v-simple-table fixed-header height="55vh">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left cursor-pointer"
                        @click="sortBy('component')"
                      >
                        Component
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('ok')"
                      >
                        V (ok)
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('info')"
                      >
                        I (info)
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('recommendation')"
                      >
                        E (recomm.)
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('warning')"
                      >
                        P (warning)
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('alarm')"
                      >
                        PP (alarm)
                      </th>
                      <th
                        class="text-center cursor-pointer"
                        @click="sortBy('critical')"
                      >
                        PPP (critical)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in components_result" :key="item.key">
                      <td>{{ item.component }}</td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.ok == 0 }"
                        @click="selectRow('ok', item)"
                      >
                        {{ item.ok }}
                      </td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.info == 0 }"
                        @click="selectRow('info', item)"
                      >
                        {{ item.info }}
                      </td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.recommendation == 0 }"
                        @click="selectRow('recommendation', item)"
                      >
                        {{ item.recommendation }}
                      </td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.warning == 0 }"
                        @click="selectRow('warning', item)"
                      >
                        {{ item.warning }}
                      </td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.alarm == 0 }"
                        @click="selectRow('alarm', item)"
                      >
                        {{ item.alarm }}
                      </td>
                      <td
                        class="state-item"
                        v-bind:class="{ lowlight: item.critical == 0 }"
                        @click="selectRow('critical', item)"
                      >
                        {{ item.critical }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <ResultDetail
        @closed="resultDetailClosed"
        v-if="showDetail"
        :reports="reports_count"
        :selectedRow="selectedRow"
      ></ResultDetail>
    </v-layout>

    <LightBox
      ref="lightbox"
      :show-caption="false"
      :show-light-box="false"
      :media="media"
    ></LightBox>



  </v-container>



</template>


<script lang="ts">
import ResultDetail from "../components/ResultDetail";
import LightBox from "vue-it-bigger";
import("vue-it-bigger/dist/vue-it-bigger.min.css"); // when using webpack




import { EventBus } from "@/event-bus";
import {
  filterComponents,
  aggResultsMachines,
} from "@/services/discover.service";

export default {
  components: {
    ResultDetail,
    LightBox,
  },


  data() {
    return {
      dialogResults: false,
      chartLoaded: false,
      machineFilter: null,
      imgData: null, 
    media: [], 
      searchkey: null,
      mapSort: {},
      total_results: 0,
      processing: false,

      db_result: [],
      offset: 0,
      limit: 500,
      loading: false,
      has_more: false,
      switchMachine: false,
      machineKeyword: null,
      selectedRow: {},
      showLoader: false, 
      showDetail: false,

      rows: [],
    };
  },
  computed: {
    reports_count() {
      return this.$store.state.machineReports;
    },

    components_result() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.db_result.filter((item) => {
          return (
            !item.component ||
            item.component
              .toLowerCase()
              .indexOf(this.searchkey.toLowerCase()) !== -1
          );
        });
      }

      return this.db_result;
    },

    machine_list: function () {
      let list = [];
      if (this.$store.state.aggregationMachines) {
        Object.entries(this.$store.state.aggregationMachines).forEach(
          ([key, entry]) => {
            list.push(entry["key"]);
          }
        );
      }
      return list.sort();
    },
  },
  mounted() {
    this.$store.state.headerTitle = "8.2 IDAT - Components";

    EventBus.$on("img_selected", this.imgSelectedHandler);

    aggResultsMachines({})
      .then((x) => {
        if (x.data && x.data.aggregations) {
          this.$store.commit(
            "set_aggregationMachines",
            x.data.aggregations["Anlagentyp"].buckets
          );
        }
      })
      .catch((e) => {
        this.$store.dispatch("api_error", e);
      });
  },
  destroyed() {
    EventBus.$off("img_selected", this.imgSelectedHandler);
  },
  methods: {
    imgSelectedHandler(list) {
        this.media = list

        this.openGallery()
    },
    openGallery() {
      this.$refs.lightbox.showImage(0);
    }, 
    resultDetailClosed() {
      this.showDetail = false;
    },
    selectRow(state, item) {
      if (item[state] > 0) {
        this.selectedRow = {
          state: state,
          component: item.component,
          machine: this.machineFilter,
          switchMachine: this.switchMachine,
          machineKeyword: this.machineKeyword,
        };
        this.showDetail = true;
      }
    },
    findCmpInResult(key) {
      for (let k = 0; k < this.db_result.length; k++) {
        if (this.db_result[k].component == key) {
          return this.db_result[k];
        }
      }
      return null;
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },
    sortBy(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.db_result = this.db_result.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    filterStart(btnClick) {
      this.chartLoaded = false;

      if (
        (!this.switchMachine &&
          this.machineFilter &&
          this.machineFilter.length > 0) ||
        (this.switchMachine &&
          this.machineKeyword &&
          this.machineKeyword.length > 1)
      ) {
        //console.log("machineFilter", this.machineFilter)

        if (btnClick) {
          this.db_result = [];
          this.offset = 0;
          this.has_more = true;
        }

        this.mapComp = {};

        this.processing = true;

        filterComponents({
          machineFilter: this.machineFilter,
          machineKeyword: this.machineKeyword,
          switchMachine: this.switchMachine, 
          offset: this.offset,
          limit: this.limit,
        }).then((x) => {
          console.log(x.data);
          this.processing = false;

          this.$store.commit("set_machineReports", 0);

          if (x.data.aggregations["BauteilStatus"]) {
            this.total_results =
              x.data.aggregations["BauteilStatus"].buckets.length;

            for (
              let k = 0;
              k < x.data.aggregations["BauteilStatus"].buckets.length;
              k++
            ) {
              let entry = this.findCmpInResult(
                x.data.aggregations["BauteilStatus"].buckets[k].key.Bauteil
              );

              if (entry) {
                entry[
                  x.data.aggregations["BauteilStatus"].buckets[k].key.Status
                ] = x.data.aggregations["BauteilStatus"].buckets[k].doc_count;
              } else {
                entry = {
                  component:
                    x.data.aggregations["BauteilStatus"].buckets[k].key.Bauteil,
                  ok: 0,
                  info: 0,
                  warning: 0,
                  recommendation: 0,
                  alarm: 0,
                  critical: 0,
                };

                entry[
                  x.data.aggregations["BauteilStatus"].buckets[k].key.Status
                ] = x.data.aggregations["BauteilStatus"].buckets[k].doc_count;
                this.db_result.push(entry);
              }

              if (x.data.aggregations["Bericht"]) {
                this.$store.commit(
                  "set_machineReports",
                  x.data.aggregations["Bericht"].buckets.length
                );
              }

              //console.log( x.data.aggregations['BauteilStatus'].buckets[k].doc_count, x.data.aggregations['BauteilStatus'].buckets[k].key.Status, x.data.aggregations['BauteilStatus'].buckets[k].key.Bauteil )
            }
          }
        });
      } else {
        alert("Please select a machine");
      }
    },
  },
};
</script>

<style  lang="scss">
.title-sm {
  font-size: 1rem;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.list .row {
  display: flex;
}

.list .row .data-cell,
.data-header-cell {
  position: relative !important;
  width: auto !important;
}

revogr-focus,
.selection-border-range,
.edit-input-wrapper {
  width: 100% !important;
}

.lowlight {
  color: #e7e7e7;
}

.state-item {
  text-align: center;
  cursor: pointer;
}

.state-item:hover {
  background-color: #546e7a;
  color: #ffffff;
}
</style>